import React from 'react'

function TimeLineElement({children}) {
  return (
    <div>
        {children}
    </div>
  )
}

export default TimeLineElement