import React, { useEffect } from 'react'
import IndexPage from '../components/Layout/IndexPage'
import ContactForm from "../components/ContactForm";

function Contact() {

  useEffect(() => {
    if (document) {
      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href = "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";

      document.head.appendChild(stylesheet);
	  document.title = 'FlightsUnlocked | Contact Us';
    }
  }, []);
  return (
    <IndexPage>
      <div className='min-h-[89vh]  bg-[#f0f0f0] py-20'>
        <div className="mt-12 container mx-auto">
        <h1 className='text-center text-[40px] my-5 font-bold text-[#1E88E5]'>Contact us</h1>
        <p className='mt-10'>
        Please fill the form below and we will get back in touch with you shortly. If you are unable to fill the form, you may email our customer support team at <a href="support@flightsunlocked.com" class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">support@flightsunlocked.com</a>
		
	  <div className="py-6">
          <ContactForm />
        </div>
		
        </p>
        </div>
      
        </div>
</IndexPage>
  )
}

export default Contact