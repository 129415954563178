import React, { useEffect } from 'react'
import FlightCardTxt from './FlightCardTxt'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import WifiIcon from '@mui/icons-material/Wifi';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
function TripRow(props) {

    useEffect(() => {
      console.log(props)
    }, [props])
    
  return (
    <>
     <div className="flex justify-between mt-5">
            <p className='text-xs text-slate-500 mb-2'>{props.fromCity} ({props.fromIATA}) -- {props.toCity} ({props.toIATA})</p>
     
            <div className="flex items-center gap-1">
                <h1 className='text-xs text-[grey]'>{props.tripClass}</h1>
                <WifiIcon style={{fontSize : "15px", color: "grey"}}/>
                <PlayCircleOutlineIcon style={{fontSize : "15px", color: "grey"}}/>
                <PowerOutlinedIcon style={{fontSize : "15px", color: "grey"}}/>
            </div>
            </div>
            
    <div className='flex flex-col md:grid md:grid-cols-7 gap-4 md:items-center   w-full border rounded-lg '>
       
    <div className="airline flex flex-col items-center  col-span-2">

      <div className="flex items-center">
      <img width="80px" src={props.airlineLogo} alt="airline" className=' max-w-none ' />
   
      </div>


    </div>
    <div className="path col-span-5">
    
     <div className="flex flex-col-reverse md:flex-row  items-center">
         <div className='flex items-center justify-between w-full'>
         <FlightCardTxt first={props.depDate} second={props.fromIATA} />
         <div className='flex flex-col items-center'>
         <h1 className='text-xs text-slate-400'>{props.depDuration}</h1>
         <div className="line h-[2px] w-[200px] xl:w-[350px] bg-[#1E88E5]  mx-2 rounded-xl my-1"></div>
         <h1 className='text-xs text-slate-400'>{props.airNumber}</h1>
         </div>
        
         <FlightCardTxt first={props.depArriveDate} second={props.toIATA} />
         </div>
         

     
     </div>
    
   </div>
    
   </div>
   {props.layoverTime != null &&
   <div className='bg-[#f6f6f6] p-3 my-2 rounded-lg'>
  
    
    <h1 className='text-sm text-center'>Change planes in {props.toCity} ({props.toIATA})</h1>
    <h1 className='text-sm text-center'>Layover Time: {props.layoverTime}</h1>

    
   </div>
}
   </>
  )
}

export default TripRow