import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import FlightCardTxt from './FlightCardTxt'
import FlightRow from './FlightRow'
import moment from 'moment'
import { useSelector } from 'react-redux'

function FlightCard(props) {
  const rate = useSelector((state) => state.rate)
  const [currencyRate, setCurrencyRate] = React.useState(useSelector((state) => state.rate));

  const curr = useSelector((state) => state.currency)
  const [currency, setCurrency] = React.useState(useSelector((state) => state.currency));
  useEffect(() => {
    console.log(currencyRate);
  }, [])
  
  useEffect(() => {
    setCurrency(curr)
    setCurrencyRate(rate.state)
    
  }, [rate])
  
  let flight = props.flight
  useEffect(() => {
    console.log(props.flight);
  }, [])
  
  return (
    <div className='flightCard flex flex-col xl:grid xl:grid-cols-5 gap-4 justify-between items-center  bg-white p-3 md:p-5 min-h-[240px] rounded-lg my-10 w-full'>
         <div className="col-span-4">
         <FlightRow 
         depAirlineLogo={props.flight.depAirline?.logo}
         depAirlineName={props.flight.depAirline?.name}
         returnAirlineLogo={props.flight.returnAirline?.logo}
         returnAirlineName={props.flight.returnAirline?.name}
         depDate={moment(props.flight.depDate).format('lll') }
         depArriveDate = {moment(props.flight.depArriveDate).format('lll') }
         fromIATA={props.flight.fromIATA?.IATA_code}
         toIATA={props.flight.toIATA?.IATA_code}
         depStop={props.flight.depStop}
         round={props.flight.tripType == "round"}
         returnDate={moment(props.flight.returnDate).format('lll') }
         returnArriveDate={moment(props.flight.returnArriveDate).format('lll') }
         returnStop={props.flight.returnStop}
         depDuration={props.flight.flightDuration}
         returnDuration={props.flight.returnFlightDuration}
         depAirNumber={props.flight.depAirNumber}
         returnAirNumber={props.flight.returnAirNumber}
         />

         <div className="flex justify-center mt-5">
         {props.search.adultNbr != 0 &&<h1 className='flex items-center text-xs mx-3'>+12yrs : <span className='font-bold mx-1 text-sm text-[orange]'> {props.search.adultNbr}</span></h1>}
         {props.search.kidsNbr != 0 && <h1 className='flex items-center text-xs mx-3'>2-12yrs :<span className='font-bold mx-1 text-sm text-[orange]'> {props.search.kidsNbr} </span></h1>}
           {props.search.babiesNbr != 0 && <h1 className='flex items-center text-xs mx-3'>0-2yrs : <span className='font-bold mx-1 text-sm text-[orange]'> {props.search.babiesNbr}</span></h1>}
         </div>
         </div>
         
        



          <div className="flex flex-row xl:flex-col justify-between xl:justify-center w-full xl:col-span-1 mt-5 xl:mt-0">
        
            <h1 className='text-[30px] mb-2'><span>{parseFloat((props.flight.flightPrice * currencyRate).toFixed(2))} <span className='text-sm'>{currency.state}</span></span></h1>
            <Link to={`/checkout/`+props.flight._id} state={props.search}><Button className='bookBtn'>Book now</Button></Link>
            <h1 className='my-2 hidden xl:block'>Price per passenger</h1>
            <h1 className='text-slate-400 hidden xl:block'>{props.flight.tripType == "round" ? "Roundtrip" : "Direct Flight  "}</h1>
          </div>

       
    </div>
  )
}

export default FlightCard