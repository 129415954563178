import React from 'react'

function FlightCardTxt(props) {
  return (
    <div>
       <h1 className='font-bold text-xs whitespace-wrap w-20'>{props.first}</h1>
       <h1 className='text-slate-400 whitespace-nowrap'>{props.second}</h1>
    </div>
  )
}

export default FlightCardTxt