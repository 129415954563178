import './App.css';
import { Routes, Route } from "react-router-dom"
import Home from './pages/Home';
import SearchResult from './pages/SearchResult';
import BookingInfos from './pages/BookingInfos';
import Checkout from './pages/Checkout';
import AddFlight from './pages/AddFlight';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import About from './pages/About';
import How from './pages/How';
import Contact from './pages/Contact';
import ContactForm from "./components/ContactForm";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/result" element={<SearchResult />} />
        <Route path="/checkout/:flightID" element={<Checkout />} />
        <Route path="/booking/:flightID" element={<BookingInfos />} />
        <Route path="/255255" element={<AddFlight />} />

        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about" element={<About />} />
        <Route path="/how" element={<How />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
