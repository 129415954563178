import React from 'react'

function Warning(props) {
  return (
    <div className='p-3 bg-white'>
        <h1 className='text-[orange] text-xl mb-2'>{props.title}</h1>
        <p>{props.content}</p>
    </div>
  )
}

export default Warning