import { createSlice } from '@reduxjs/toolkit';

export const currencySlice = createSlice({
    name: 'currency',
    initialState: { state: "USD" },
    reducers: {
        changeCurrency: (state, action) => ({
            state: action.payload

        }),

    }
})


// this is for dispatch
export const { changeCurrency } = currencySlice.actions;

// this is for configureStore
export default currencySlice.reducer;