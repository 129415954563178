import React, { useEffect } from 'react'
import FlightCardTxt from './FlightCardTxt'

function FlightRow(props) {


  
  return (
    <div className='flex flex-col md:grid md:grid-cols-7 gap-4 md:items-center   w-full'>
    
    <div className="airline flex flex-row justify-between md:justify-start  md:flex-col items-center  col-span-2">

      <div className="flex items-center">
      <img width="80px" src={props.depAirlineLogo} alt="airline" className=' max-w-none ' />
   
      </div>
      {(props.round ) &&
     <div className="flex items-center">
     <img width="80px" src={props.returnAirlineLogo} alt="airline" className=' max-w-none ' />
    
     </div>}

    </div>
    <div className="path col-span-5">
      {!props.return &&
     <div className="flex flex-col-reverse md:flex-row  items-center">
         <div className='flex items-center justify-between w-full'>
         <FlightCardTxt first={props.depDate} second={props.fromIATA} />
         <div className='flex flex-col items-center'>
         <h1 className='text-xs text-slate-400'>{props.depDuration}</h1>
         <div className="line h-[2px] w-[200px] xl:w-[350px] bg-[#1E88E5]  mx-2 rounded-xl my-1"></div>
         <h1 className='text-xs text-slate-400'>{props.depAirNumber}</h1>
         </div>
        
         <FlightCardTxt first={props.depArriveDate} second={props.toIATA} />
         </div>
         

         <div className="duration text-center lg-mx-5 flex justify-center">
           <FlightCardTxt  second={props.depStop == 0 ? "Direct" :props.depStop+ " stop"} />  
         </div>
     </div>}
     {(props.round || props.return) &&
      <div className="flex flex-col-reverse md:flex-row mt-10">
    <div className='flex items-center justify-between w-full'>
         <FlightCardTxt first={props.returnDate} second={props.toIATA} />
         <div className='flex flex-col items-center'>
         <h1 className='text-xs text-slate-400'>{props.returnDuration}</h1>
         <div className="line h-[2px] w-[200px] xl:w-[350px] bg-[#1E88E5]  mx-2 rounded-xl my-1"></div>
        <h1 className='text-xs text-slate-400'>{props.returnAirNumber}</h1>
         </div>
         <FlightCardTxt first={props.returnArriveDate} second={props.fromIATA} />
         </div>
      
         <div className="duration text-center lg-mx-5 flex justify-center">
           <FlightCardTxt  second={props.returnStop == 0 ? "Direct" :props.returnStop+ " stop"} />  
         </div>
  </div>
     }
   </div>
    
   </div>
  )
}

export default FlightRow