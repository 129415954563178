import { Button, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContentCard from '../components/ContentCard'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import BookInfo from '../components/BookInfo';
import FlightDetails from '../components/FlightDetails';
import { Link, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

import IndexPage from '../components/Layout/IndexPage'
import { useSelector } from 'react-redux';

function Checkout() {

  const [taxes, setTaxes] = useState(33.55)
  const [bookDetails, setBookDetails] = useState([  {item : "Taxes & Fees", value: taxes}])


  const [travelProtection, setTravelProtection] = useState("false")
  const [refundDrop, setRefundDrop] = useState("false")
  const [refundDelay, setRefundDelay] = useState("false")
  const [selectSeat, setSelectSeat] = useState("false")
  const [seatFlight, setSeatFlight] = useState("")

  const [flightId, setFlightId] = useState("")

  const [flightInfos, setFlightInfos] = useState(null)

  const [passengers, setPassengers] = useState(0)

  const [passengersSeats, setPassengersSeats] = useState([])

  const [totalPrice, setTotalPrice] = useState(0)

  const [first, setfirst] = useState("")

  const rate = useSelector((state) => state.rate)
  const [currencyRate, setCurrencyRate] = React.useState(useSelector((state) => state.rate));

  const curr = useSelector((state) => state.currency)
  const [currency, setCurrency] = React.useState(useSelector((state) => state.currency));

  
  useEffect(() => {
    setCurrency(curr)
    setCurrencyRate(rate.state)
    
  }, [rate])
  
  useEffect(() => {
    document.title = 'FlightsUnlocked | Checkout';
  }, []);

  const location = useLocation();
  const params = useParams();

  const getFlight = async(flightId)=>{
    await axios.get(`https://flightsunlocked.com:2096/api/flight/`+flightId).then(function (response) {
    console.log(response.data?.data)
    setFlightInfos(response.data?.data)
  })
}


  useEffect(() => {
    console.log(params.flightID);
     getFlight(params.flightID)
   
  
  }, [params.flightID])

  const countPrice = ()=>{
    setTotalPrice((flightInfos?.flightPrice*flightInfos?.adultNbr+ flightInfos?.flightPrice*flightInfos?.kidsNbr+ flightInfos?.flightPrice*flightInfos?.babiesNbr) + taxes)

  }

  useEffect(() => {
    let arr = bookDetails

    const index = arr.findIndex(object => {
      return (object.item === 'Price Per Passenger' && object.value === flightInfos?.flightPrice);
    });
   
    if (index == -1 && flightInfos?.flightPrice != undefined) {
  
    arr.unshift({item : "Price Per Passenger", value: flightInfos?.flightPrice})
  
    
    setBookDetails([...arr])
    }

    countPrice()

    setPassengers(flightInfos?.adultNbr+flightInfos?.kidsNbr+flightInfos?.babiesNbr)

  }, [flightInfos])
  
  

  const handleTravelProtection = async (e)=>{

  
    await setTravelProtection(e.target.value)

    if (e.target.value === "true"){
      
      let arr = bookDetails
      arr.push({ item : "Travel Protection", value :14.22})
    
      
        setBookDetails([...arr])

        let newPrice = totalPrice+ 14.22
        setTotalPrice(Math.round(newPrice * 100) / 100)
    }
       else {
        let arr = bookDetails
        const index = arr.findIndex(object => {
          return object.item === 'Travel Protection';
        });
     
        if (index > -1) { // only splice array when item is found
          arr.splice(index, 1); // 2nd parameter means remove one item only
        }
  
          setBookDetails([...arr])

          let newPrice = totalPrice- 14.22
          setTotalPrice(Math.round(newPrice * 100) / 100)
       
    }

  }

  const handleRefundDrop =async (e)=>{

  
    await setRefundDrop(e.target.value)
 
    if (e.target.value == "true"){
      
      let arr = bookDetails
      arr.push({ item : "Price Match Promise", value : 3.06})
        await setBookDetails([...arr])


        let newPrice = totalPrice + 3.06
        setTotalPrice(Math.round(newPrice * 100) / 100)
   
    }
       else {
        let arr = bookDetails
        const index = arr.findIndex(object => {
          return object.item === 'Price Match Promise';
        });
  
        if (index > -1) { // only splice array when item is found
          arr.splice(index, 1); // 2nd parameter means remove one item only
        }
  
          setBookDetails([...arr])

          let newPrice = totalPrice - 3.06
          setTotalPrice(Math.round(newPrice * 100) / 100)
    }

  }


  const handleRefundDelay =async (e)=>{

  
    await setRefundDelay(e.target.value)
  
    if (e.target.value == "true"){
      
      let arr = bookDetails
      arr.push({ item : "Flight Delay Compensation", value : 18.50})
        await setBookDetails([...arr])


        let newPrice = totalPrice + 18.50
        setTotalPrice(Math.round(newPrice * 100) / 100)
    
    }
       else {
        let arr = bookDetails
        const index = arr.findIndex(object => {
          return object.item === 'Flight Delay Compensation';
        });
  
        if (index > -1) { // only splice array when item is found
          arr.splice(index, 1); // 2nd parameter means remove one item only
        }
  
          setBookDetails([...arr])


          let newPrice = totalPrice - 18.50
          setTotalPrice(Math.round(newPrice * 100) / 100)
        
    }

  }

  const handleSelectSeat = (e)=>{
     setSelectSeat(e.target.value)
     console.log(selectSeat);
  }

  const handleAddSeatToFlight = (e)=>{
   

     setSeatFlight(e.target.value);
    console.log(seatFlight)
  }

  useEffect(() => {
   console.log(seatFlight);
  }, [seatFlight])
  
  
    if (!flightInfos) return null
   return (
    
    <IndexPage>
    <div className=' bg-[#F0F0F0] min-h-[100vh] py-20 px-6 lg:px-3 w-full'>

      <div className='container flex flex-col lg:flex-row   mx-auto my-10 gap-10'>
        

      <div className='lg:w-3/4 xl:w-3/7'>
      <ContentCard  title ="Flight details">
        <FlightDetails  flight={flightInfos}/>
      </ContentCard>



      <ContentCard  w="mt-10" title ="Travel Protection (Recommended)">
        <div className="grid grid-cols-7 gap-4">
          <div className="shield col-span-7 md:col-span-2 flex flex-col items-center py-3">
            <PrivacyTipIcon style={{fontSize: "60px", color: "#1E88E5"}}/>
            <p className='text-center text-sm my-2'>The service costs {parseFloat((14.22 * currencyRate).toFixed(2))}<span className='text-xs'>{currency.state}</span>.</p>
          </div>


          <div className='col-span-7 md:col-span-5'>
            <p className='text-sm'>Reasons you might need travel protection</p>
            <div className=" pl-5 grid sm:grid-cols-2 lg:grid-cols-3 gap-2 my-3">
              <li className='text-sm'>Trip cancellation</li>
              <li className='text-sm'>Trip delay</li>
              <li className='text-sm'>Inclement weather</li>
              <li className='text-sm'>Trip interruption</li>
              <li className='text-sm'>Baggage delay</li>
              <li className='text-sm'>Mechanical issues</li>
            </div>
          </div>


        
        </div>
        <FormControl>
     
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={(e)=> handleTravelProtection(e)}
      >
        <FormControlLabel   control={<Radio size='small' value={true} checked={travelProtection === "true"}  />} label="Yes, I want Travel Protection for my trip." />
        <FormControlLabel  control={<Radio size='small' value={false} checked={travelProtection === "false"}   />} label="No, I'm willing to risk my trip." />

      </RadioGroup>
    </FormControl>



      </ContentCard>




      <ContentCard  w="mt-10" title ="Price Match Promise">
        <div>
          <p className='text-sm mb-3'>We'll auto check your flight price, and refund the difference if the price drops.</p>
          <p className='text-sm font-bold mb-1'>How it works</p>
          
            <li className='text-sm ml-3'>We'll check for price drops daily on your behalf for this flight.</li>
            <li className='text-sm ml-3'>You can always keep track of your flight price changes.</li>
            <li className='text-sm ml-3'>If we find a lower price, we'll automatically give you a refund of the difference in price.</li>
            <li className='text-sm ml-3'>You'll be refunded the best price drop to your original payment method within 7 days after your trip is complete.</li>
            <p className='text-sm my-3'>The service costs <span className='font-bold'>{parseFloat((3.06 * currencyRate).toFixed(2))}<span className='text-xs'>{currency.state}</span> </span>per passenger.</p>


            <FormControl>
     
     <RadioGroup
       aria-labelledby="demo-radio-buttons-group-label"
       defaultValue=""
       name="radio-buttons-group"
       onChange={(e)=> handleRefundDrop(e)}
     >
       <FormControlLabel    control={<Radio size='small' value={true} checked={refundDrop === "true"}  />} label=" Yes, I want to get a refund if the price drops." />
       <FormControlLabel  control={<Radio size='small' value={false} checked={refundDrop === "false"} />} label="No, I don't want a refund on a price drop." />

     </RadioGroup>
   </FormControl>
        </div>
      </ContentCard>




      <ContentCard w="mt-10" title ="Flight Delay Compensation">
        <div>
          <p className='text-sm mb-3'>Our partner, AirHelp, offers:</p>
          <li className='text-sm ml-3'>Up to <b> <span className='font-bold'>{parseFloat((700 * currencyRate).toFixed(2))}<span className='text-xs'>{currency.state}</span> </span> compensation </b> in case of delays, cancellation, or overbooking caused by the airline (or up to <span className='font-bold'>{parseFloat((1600 * currencyRate).toFixed(2))}<span className='text-xs'>{currency.state}</span> </span> for delayed/damaged/lost baggage based o applicable law).</li>
          <li className='text-sm ml-3'><b>24/7 emergency line</b> to discuss your consumer rights in case of a disrupted flight.</li>
          <li className='text-sm ml-3'>Helped more than <b>7 million passengers</b> get their compensation, stress and hassle free.</li>

          <p className='text-sm my-3'>Flight Delay Compensation is a service that helps you get up to <span className='font-bold'>{parseFloat((700 * currencyRate).toFixed(2))}<span className='text-xs'>{currency.state}</span> </span> compensation from the airline when your flight is delayed, cancelled or if you are denied boarding (or up to <span className='font-bold'>{parseFloat((1600 * currencyRate).toFixed(2))}<span className='text-xs'>{currency.state}</span> </span> for delayed/damaged/lost baggage based o applicable law). The fee covers all flights within this booking. If you are not eligible for compensation, the service costs for this product are non-refundable.</p>
          <p className='text-sm my-3'>The service costs <span className='font-bold'>{parseFloat((18.50 * currencyRate).toFixed(2))}<span className='text-xs'>{currency.state}</span> </span> per passenger.</p>

          <FormControl>
     
     <RadioGroup
       aria-labelledby="demo-radio-buttons-group-label"
       defaultValue=""
       name="radio-buttons-group"
       onChange={(e)=>handleRefundDelay(e)}
     >
       <FormControlLabel   control={<Radio size='small' value={true} checked={refundDelay === "true"}  />} label="Yes, I want to get a refund if the flight is delayed or cancelled." />
       <FormControlLabel  control={<Radio size='small' value={false} checked={refundDelay === "false"} />} label="No, I don't want a refund in case the flight is delayed or cancelled." />

     </RadioGroup>
   </FormControl>
        </div>
      </ContentCard>



      <ContentCard  w="mt-10" title ="Select Your Seats">
        <div>
          <p>Do you wish to select your seats?</p>
          <p className='mb-3'>Seat choice is not guaranteed, please check with airline to confirm seat selection.</p>


         {selectSeat !== "true"  ?
          <FormControl>
     
     <RadioGroup
       aria-labelledby="demo-radio-buttons-group-label"
       onChange={(e)=>handleSelectSeat(e)}
       name="radio-buttons-group"
     >
       <FormControlLabel  value="yes"  control={<Radio size='small' value={true} checked={selectSeat === "true"} />} label="Yes, I want to select seats." />
       <FormControlLabel value="no" control={<Radio size='small' value={false} checked={selectSeat === "false"}/>} label="No, assign my seats automatically." />

     </RadioGroup>
   </FormControl>
   :  <div className='mt-10'>
     <FormControl  fullWidth>
   <InputLabel id="demo-simple-select-label">Select flight</InputLabel>
   <Select
   className='mt-1'
     labelId="demo-simple-select-label"
     id="demo-simple-select"
  
     label="Select flight"
     onChange={(e)=>handleAddSeatToFlight(e)}
     value={seatFlight}
   >
     <MenuItem value="firstFlight">{flightInfos?.fromIATA?.IATA_code} - {flightInfos?.toIATA?.IATA_code} </MenuItem>
     {flightInfos?.tripType == "round" && <MenuItem value="secondFlight">{flightInfos?.toIATA?.IATA_code} - {flightInfos?.fromIATA?.IATA_code}</MenuItem>}
    
   </Select>
 </FormControl>

 
  { seatFlight.length !== 0 &&
  

    Array.apply(null, Array(passengers)).map((val,idx) => 
    
 <div className='mt-8'>
  <FormControl  fullWidth>
   <InputLabel id="demo-simple-select-label">Select seat for passenger #{idx+1}</InputLabel>
   <Select
   className='mt-1'
     labelId="demo-simple-select-label"
     id="demo-simple-select"
     value={passengersSeats[idx]}
     label={`Select seat for passenger #${idx+1}`}
     onChange={(e)=>setPassengersSeats([...passengersSeats],e.target.value)}
   > 
  
     <MenuItem value={"window"}>Window</MenuItem>
     <MenuItem value={"middle"}>Middle</MenuItem>
     <MenuItem value={"aisle"}>Aisle</MenuItem>
  
   
   </Select>
 </FormControl>
 </div>
)
}
 </div>
}
        </div>
      </ContentCard>


      <div className='my-10'>
        <div>
          <p className='text-[30px]'>Things To Know</p>
          <hr />
          <p className='text-sm my-2'>Fare Rules: Airline schedules can change. Please visit your airline's website to reconfirm your flight information and check-in location prior to each departure. Your airline tickets are non-refundable and non-changeable. Name changes or adjustments are not allowed.</p>
        </div>
     
       </div>
      </div>
     
     


      
      <div className='lg:w-1/4 xl:w-3/7 mb-10 lg:mb-0 lg right-1 xl:right-40 overflow'>
      <ContentCard   title ="Book Your Flight">
        <div className='mb-5'>
          <BookInfo item="12+ yrs" value={flightInfos?.adultNbr}/>
          <BookInfo item="2-12 yrs" value={flightInfos?.kidsNbr}/>
          <BookInfo item="0-2 yrs" value={flightInfos?.babiesNbr}/>
        </div>
        <div className='mb-5'>
          {bookDetails.map((info,idx) => <BookInfo key={idx} item={info.item} value={parseFloat((info.value * currencyRate).toFixed(2))+" "+currency.state}/>)}
    
        </div>
        <div>
          <BookInfo item="Total Price" value={parseFloat((totalPrice * currencyRate).toFixed(2))+" "+currency.state} bold/>

        </div>
        <div className="mt-5">
        <Link to={"/booking/"+flightInfos?._id} state={{flight : flightInfos,booking : bookDetails, total : totalPrice, passengers : passengers,persons : {adultNbr : flightInfos?.adultNbr, kidsNbr : flightInfos?.kidsNbr, babiesNbr : flightInfos?.babiesNbr}}}><Button className='bookBtn orangeBtn'>Book now</Button></Link>

        </div>
      </ContentCard>

      <ContentCard  marginTop title ="Support">
      <p className='text-center'><span className='text-[orange] mr-1 font-bold'>{flightInfos.depAirline?.name}</span> are happy to help with any issues. Contact information is available after the booking.</p>

      </ContentCard>
      <ContentCard noHeader  marginTop title ="Support">
      <p className='text-center text-lg'> This reservation is covered by our <span className='text-[orange] mr-1 font-bold'> Buyer Protection Policy. </span></p>

      </ContentCard>

      </div>
      
     


      </div>





















     
    
      
 
       

     
    </div>
    </IndexPage>
 )
}

export default Checkout