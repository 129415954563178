import React from 'react'

function TimeLineContent({children}) {
  return (
    <div className=' py-5 mx-5 px-5  border-l-2 border-[#1E88E5]'>
        {children}
    </div>
  )
}

export default TimeLineContent