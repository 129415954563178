import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import dayjs from 'dayjs';

function SearchForm() {

    const navigate = useNavigate()
    const [IATAs, setIATAs] = useState([])
 


    const [searchParams, setSearchParams] = useState({fromIATA : null, toIATA: null,departureDate : new Date().toUTCString(), returnDate : null, tripType: "round", adultNbr: 1, kidsNbr: 0,babiesNbr : 0, flightClass : ""})


    const searchIATA = (txt)=>{
      console.log("version");
      axios.get(`https://flightsunlocked.com:2096/api/airport/search?text=${txt}`).then((response) => {
        console.log(response.data.docs);
        setIATAs(response.data.docs)
      });
    }


    const submitSearch = (e) =>{
      e.preventDefault()
      console.log(searchParams);
   
      
      axios.get(`https://flightsunlocked.com:2096/api/flight/?`, {params : searchParams}).then((response) => {
        console.log(response.data);

        navigate( "/result", {state : {results : response.data, search : searchParams}})
    
      });

    }
  return (
    <>
   
    <form onSubmit={(e)=>submitSearch(e)} className='bg-white md:bg-[#ffffffcd] searchForm w-[95%]   md:w-3/4   p-3 px-5  top-[600px]  md:top-[58%] rounded-xl'>
      
        <div className="flex justify-center">
      
    
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={searchParams.tripType}
        onChange={(e)=> setSearchParams({...searchParams,tripType : e.target.value})}
  
        
      >
        <div className='flex'>
        <FormControlLabel  value="round" control={<Radio />} label="Round Trip" />
        <FormControlLabel value="direct" control={<Radio />} label="Direct Flights Only" />
        </div>
        

      </RadioGroup>
 
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5">

         <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={IATAs}
      getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
   
      onChange={(e,newValue)=> setSearchParams({...searchParams,fromIATA :  newValue.IATA_code})}
      renderInput={(params) => <TextField 
        required
        value={searchParams.fromIATA}
        onChange={(e)=>searchIATA(e.target.value)} style={{fontSize : "4px !important"}} {...params} 
      
        label="From (City or airport)" />}
    />
       <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={IATAs}
      getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
      onChange={(e,newValue)=> setSearchParams({...searchParams,toIATA :  newValue.IATA_code})}
      renderInput={(params) => <TextField 
        required
         style={{fontSize : "4px !important"}} {...params} 
      value={searchParams.toIATA}
      onChange={(e)=>searchIATA(e.target.value)}
       label="To (City or airport)" />}
    />
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Departure Date"
        value={searchParams.departureDate}
        onChange={(e)=> {setSearchParams({...searchParams,departureDate : new Date(e).toUTCString()})}}
        required
        renderInput={(params) => <TextField {...params} />}
        minDate={new Date()}
      />
    </LocalizationProvider>
   {searchParams.tripType !== "direct" &&
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      required
        label="Return Date"
        value={searchParams.returnDate}
        onChange={(e)=> setSearchParams({...searchParams,returnDate : e})}
        renderInput={(params) => <TextField {...params} />}
        minDate={new Date()}
      />
    </LocalizationProvider>
}
    </div>
    <div className="grid  lg:grid-cols-2 gap-4 mt-5">
        <div className='grid justify-center sm:grid-cols-3 gap-4'>
        <div className='flex flex-col items-start'>
        <label className="text-xs font-100 mb-1">+12 yrs</label>
        <ButtonGroup  aria-label="small outlined">
        <Button  className='incBtn' onClick={()=>{if (searchParams.adultNbr >0){ setSearchParams({...searchParams, adultNbr : searchParams.adultNbr -1})}}}>-</Button>
        <span className='w-[40px] h-[40px]  p-2   text-sm  border-t-2 border-b-2 border-[#1E88E5] text-[#000]'>{searchParams.adultNbr}</span>
        <Button className='incBtn'  onClick={()=>setSearchParams({...searchParams, adultNbr : searchParams.adultNbr +1})}>+</Button>
      </ButtonGroup>
      </div>

      <div className='flex flex-col items-start'>
        <label className="text-xs font-100 mb-1">2-11 yrs</label>
        <ButtonGroup  aria-label="small outlined">
        <Button  className='incBtn' onClick={()=>{if (searchParams.kidsNbr >0){ setSearchParams({...searchParams, kidsNbr : searchParams.kidsNbr -1})}}}>-</Button>
        <span className='w-[40px] h-[40px]  p-2  text-sm  border-t-2 border-b-2 border-[#1E88E5] text-[#000]'>{searchParams.kidsNbr}</span>
        <Button className='incBtn'  onClick={()=>setSearchParams({...searchParams, kidsNbr : searchParams.kidsNbr +1})}>+</Button>
      </ButtonGroup>
      </div>

      <div className='flex flex-col items-start'>
        <label className="text-xs font-100 mb-1">0-2 yrs</label>
        <ButtonGroup  aria-label="small outlined">
        <Button  className='incBtn' onClick={()=>{if (searchParams.babiesNbr >0){ setSearchParams({...searchParams, babiesNbr : searchParams.babiesNbr -1})}}}>-</Button>
        <span className='w-[40px] h-[40px]  p-2   text-sm  border-t-2 border-b-2 border-[#1E88E5] text-[#000]'>{searchParams.babiesNbr}</span>
        <Button className='incBtn'  onClick={()=>setSearchParams({...searchParams, babiesNbr : searchParams.babiesNbr +1})}>+</Button>
      </ButtonGroup>
      </div>

        </div>
        <div>
        <FormControl  fullWidth>
        <InputLabel id="demo-simple-select-label">Class</InputLabel>
        <Select
        className='mt-1'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchParams.flightClass}
          onChange={(e)=> setSearchParams({...searchParams,flightClass : e.target.value})}
          label="Class"
        
        >
           <MenuItem value={"Economy"}>Economy</MenuItem>
                            <MenuItem value={"Premium Economy"}>Premium Economy</MenuItem>
                            <MenuItem value={"Business"}>Business</MenuItem>
                            <MenuItem value={"First"}>First</MenuItem>
        </Select>
      </FormControl>
        </div>
   
    </div>
    <div className='flex mt-5 justify-center md:justify-end '>
      <Button type='submit'  className='bookBtn' style={{width: "150px"}}>Search now</Button>
    </div>
    </form>
    </>
  )
}

export default SearchForm

