import React from 'react'
import DestinationCard from './DestinationCard'

function DestinationSection() {
  return (
    <div className='pb-12'>
        <div className="container mx-auto">
            <h1 className='text-center text-[30px] font-bold my-12'>WHAT ARE THE DESTINATIONS WITH <span className='text-[orange]'> THE CHEAPEST FLIGHTS ?</span></h1>
            <div className='grid  md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto w-4/5'>
                <DestinationCard price="$220" destination="Las Vegas" img={require("../../src/assets/images/Las Vigas.jpg")}/>
                <DestinationCard price="$239" destination="Chicago" img={require("../../src/assets/images/Chicago.jpg")}/>
                <DestinationCard price="$256" destination="Cancun" img={require("../../src/assets/images/Cancun.jpg")}/>
                <DestinationCard price="$279" destination="Puerto Rico" img={require("../../src/assets/images/Puerto Rico.jpg")}/>
                <DestinationCard price="$280" destination="Mexico" img={require("../../src/assets/images/Mexico.jpg")}/>
                <DestinationCard price="$297" destination="Paris" img={require("../../src/assets/images/Paris.jpg")}/>
            </div>
        </div>
    </div>
  )
}

export default DestinationSection