import { createSlice } from '@reduxjs/toolkit';

export const rateSlice = createSlice({
    name: 'rate',
    initialState: { state: 1 },
    reducers: {

        changeRate: (state, action) => ({
            state: action.payload

        }),
    }
})


// this is for dispatch
export const { changeRate } = rateSlice.actions;

// this is for configureStore
export default rateSlice.reducer;