import React, { useEffect } from 'react'
import IndexPage from '../components/Layout/IndexPage'

function PrivacyPolicy() {
  useEffect(() => {
    document.title = 'FlightsUnlocked | Privacy Policy';
  }, []);
  return (
    <IndexPage>
       <div className='min-h-[89vh]  bg-[#f0f0f0] py-20'>
        <div className="mt-12 container mx-auto">
        <h1 className='my-5 text-center text-[40px] font-bold text-[#1E88E5]'>Privacy Policy</h1>
This policy describes FlightsUnlocked’s practices regarding personally identifiable information and data (“personal information”). This policy applies to our websites, applications, email and text messages, and social media accounts (the “Platforms”). When you use the Platforms, you agree to the terms in this policy.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>The Categories of Personal Information We Collect</h1>
Platform information: When you visit the Platforms, we may collect information from you. Some of it may be personal information. This information can include search history, IP address, screen resolution, browser used, operating system and settings, access times and referring URL. If you are using a mobile device, we may also collect data that identifies your device, your settings and your location.
 
Booking information: If you book a travel product via the Platforms, we may need additional information from you. This information can include your name (and traveler’s name, if different), age, birthday, gender, passport number, redress control number, email address, telephone number, credit card information, billing address, itinerary and frequent flyer details. You can also choose to store personal information in your user account for future bookings.
 
Account information: If you choose to register, we will collect your email address, password, home airport, and other preference information you provide us. If you use a third-party service, like Facebook Login, to register or login, you are permitting us to use and store that information related to the account.
 
Trips services: If you make a booking on FlightsUnlocked or within our mobile applications, we may automatically create a “Trips” account for you, which will give you access to our “Trips” service, where we will collect your travel information to create and maintain your travel itineraries. This can include the personal information that may be included in the confirmation emails you give us access to.
 
Consumer support: If you choose to contact our consumer support services, we will collect any inquiries, complaints or other information that you may submit to our support team.
 
Promotional information:  If you choose to participate in a sweepstake, contest or similar campaign, we will collect any information you provide in relation to such activity, such as photos, images, captions or other content, in accordance with the terms provided at that time.
 
When you give us personal information, you are telling us that the information is true, accurate, complete and current. You are also telling us that you have the authorization to provide it to us.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>How We Collect Your Personal Information</h1>
Personal information you give us: We collect your personal information directly from you (e.g., when you provide us your personal information when you register or book travel, contact our customer support or participate in a contest or sweepstake).
 
If you sign up for the synchronization of your emails to our Trips service, you authorize us to access the emails in the email accounts you have connected to Trips, to regularly review these emails in order to identify those that are booking confirmations that we can import into our Trips service, to parse these emails and to add the information we have collected (including a copy of the email to your Trips account).
 
Personal information we collect passively: We also collect your personal information passively. For example, we collect information about you over time and across different websites when you visit this Platform. We also use tracking tools like cookies and beacons.
 
Platform features may make use of your device attributes and settings that will allow us to determine your physical location. Such technologies may include IP address mapping, WiFi, GPS signals, cell tower positioning or other technologies. We use this information to enhance and personalize your experience and provide you with offers and services that may be of interest to you. For example, we may help you find your closest hotel or send you messages that may be of interest to you based on your current location. FlightsUnlocked does not have control over your device settings, but we do recommend enabling location services on your device so you can take advantage of the location-based features and functionality offered.
 
Personal information we receive from third parties: We collect your personal information from third parties. This can include when you log in using a third-party platform, such as Facebook. In addition, we also integrate third party software (e.g., Google’s Invisible CAPTCHA) that collects information about users for security reasons.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>How We Use Your Personal Information</h1>
Your personal information may be used in the following ways:
 
Improving our services: To provide for you and improve our Platforms and services, to better understand the users of our Platforms and services, and to protect our property and to prevent damage to them.
 
Bookings: We and our travel partners use your personal information to process your bookings and store your itinerary information where applicable.
 
Marketing activities: We use your information for marketing purposes. These activities may include:
 
Sending you marketing emails from a FlightsUnlocked address regarding our services or those of our partners if you have opted-in to receive emails from us or have transacted with us and as permitted by law. You can easily unsubscribe from these marketing communications at any time by clicking on the “Unsubscribe” link included in the newsletter;
Displaying more relevant advertising and recommendations, or suppressing advertising and content that you might find irrelevant. This advertising might be shown to you on our Platforms as well as third-party platforms (including social media sites) and include information or offers that we, or our business partners, believe you will find interesting. Individualized advertising may be based on information collected through cookies or other tracking technologies;
Administering promotions with relevant information if you choose to participate in promotional activities (such as sweepstakes, competitions or other similar campaigns);
And communicating with you, including as follows:
 
sending you booking confirmations;
sending alerts and notifications you have subscribed to, including alerts and notifications sent to your mobile device;
soliciting reviews;
updating you regarding itineraries processed by our Trips service;
communicating with you if you have contacted FlightsUnlocked consumer support;
sending you information servicing and administrative emails;
and sending you information about services offered by FlightsUnlocked or our business partners that we believe you would be interested in.
Legal purposes: In certain cases, we may need to use your information to handle and resolve legal disputes, for regulatory investigations and compliance or to enforce the terms of use of the service.
 
Performance of a contract: The use of your personal information may be necessary to perform the services and provide you products that you have requested from us. For example, if you make a booking on our Platform, we need to collect personal information from you in order to complete the booking.
 
Legitimate interest: We may use your personal information for our legitimate interests, such as displaying relevant advertising and recommendations, and for legal purposes.
 
Consent: We may rely on your consent to use your data for certain direct marketing purposes. You can withdraw your consent anytime by contacting us and can change your settings in your account, as described below.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>Your Choices</h1>
You can manage your communication preferences in your account. Marketing email has an opt-out URL, and you can unsubscribe from such communication at any time. You cannot unsubscribe from servicing emails and administrative messages that are considered part of your account membership unless you cancel your membership.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>How We Share Your Personal Information</h1>
Corporate affiliates and change of control: We may share your personal information with our corporate affiliates, and if FlightsUnlocked itself (or part of its business) is sold or otherwise changes control, owners would have access to your personal information for the uses set out herein.
 
Service providers: We may share your personal information with suppliers who perform services on our behalf and have agreed in writing to protect and not further disclose your information.
 
Payment service providers and travel vendors: If you book through the Platforms, we may share your personal information with the payment service providers and the travel vendors with which you have booked. This can include online travel agencies, hotels, airlines, car rental companies, and travel insurance providers. These third parties will process your personal information as data controllers in accordance with their own privacy policies. If you contact our consumer support, they may need to share information about your request with the relevant travel vendor in order to assist you.
 
Business partners: We may share your personal information with various business partners. Some of these business partners may use your personal information for online behavioral advertising purposes or to offer you services or products that may interest you. We may also share your information as otherwise described to you at the time of collection. For example, if you provide information to enter a sweepstake, we may share your information with promotional partners. We may also share anonymous aggregated usage information with partners.
 
Trips shared by you: If you use or have itineraries as part of our Trips Service, you can send or grant access to your itinerary to anyone you choose. Your itinerary may contain enough details (for example, booking reference codes) to allow the recipient to cancel or modify your booking, perform a check-in, etc. You should only share your itinerary with people you trust. Please also read the detailed Trips FAQ. If you choose to display your itinerary on publicly-viewable web pages (Facebook, for instance), that information may be collected and used by others.
 
Information shared in public: If you provide us a review of your trip, you authorize us to publish it on all our Platforms under the screen name you provided. You also authorize us to aggregate it with other reviews.
 
Authorities: We may disclose personal information if required by law (e.g., to law enforcement or other authorities). This includes court orders, subpoenas and orders arising from legal processes, and administrative or criminal investigations. We may also disclose your personal information if the disclosure is necessary for the prevention, detection or prosecution of criminal acts or to prevent other damage, or in response to a legal action or to enforce our rights and claims.
 
We may also share anonymous aggregated usage information with others.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>How We Store and Protect Your Personal Information</h1>
Our servers and data centers are located in the U.S and in Switzerland, and our service providers may be located there and in other countries. By providing us personal information, you agree that your personal information may be transferred to and stored in these countries. These countries may have different and/or less stringent privacy/data protection and data security rules than those of your own country. As a result, your personal information may be subject to access requests from governments, courts, or law enforcement in those countries, according to laws in those countries. Subject to the applicable laws in such other countries, we will provide the necessary safeguards to maintain protections of your personal information (e.g., by obtaining from the data recipients contractual commitments based on the EU model clauses).
 
FlightsUnlocked has a security program intended to keep the personal information stored in our systems protected from unauthorized access and misuse. Our systems are configured with data encryption, or scrambling technologies and firewalls constructed to industry standards. We also use Secure Socket Layer (SSL) technology that protects the data you send over the Internet. Personal information may only be accessed by persons within our organizations or our third parties to carry out the uses indicated in this Privacy Policy. In addition, information collected by Google’s Invisible CAPTCHA is held in accordance with the Google Privacy Policy.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>Retention Periods</h1>
We will retain your personal information for as long as your account is active and for a period of time thereafter to allow you to re-activate your account without loss of information.
 
We will also retain your personal information as necessary to comply with our legal obligations, resolve disputes and enforce our contracts.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>Cookies and Tracking Tools</h1>
FlightsUnlocked uses cookies and tracking tools.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>Other Websites</h1>
If you click through to third-party websites or other platforms, FlightsUnlocked’s privacy policy does not apply.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>Children</h1>
FlightsUnlocked does not knowingly collect personal information from minors. If we become aware that a minor is attempting to submit personal information, we will remove this information from our records. If you are the parent/legal guardian of a minor who has given us personal information, please contact us so we can delete it.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>Changes to the Privacy Policy</h1>
We may modify this Privacy Policy at any time and without prior notice. We will notify you of material changes to how we use your personal information prior to implementing them. We may notify you about such a material change by email or prominent notice on our website. By using our services and Platforms after we post these changes, you indicate that you accept them.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>Your Rights with Respect to Personal Information</h1>
Before contacting FlightsUnlocked for any request you may have, note that you can manage your account settings in the “My Account” page on FlightsUnlocked. You can also delete your account at any time. Your rights:
 
Access:  You are entitled—at any time—to obtain information about your personal information that we store, in accordance with applicable law and without any fee. However, in exceptional cases we ask you to pay a reasonable fee before we provide the information if permitted by applicable law.
Rectification:  You may request that we rectify any of your personal information that is incomplete or incorrect.
Deletion:  You may request that we delete your account.
Objection:  You may object, at any time, to your personal information being processed for direct marketing purposes.
Restrictions of processing:  You may ask us to cease processing of your personal information (e.g., if you have objected to the processing and the existence of legitimate ground is still under consideration).
Data portability:  If applicable, you may request that we send you your Trips in a commonly-used and machine-readable format, which shall be decided on in FlightsUnlocked’s sole discretion.
Right to withdraw consent:  You have the right to withdraw your consent at any time, without affecting the lawfulness of our processing based on such consent before it was withdrawn.
We may request proof of your identity before acting upon any request. We may not be able to allow you to access certain personal information in some cases (e.g., if your personal information is connected with personal information of other persons or for legal reasons). In such cases, we will provide you with an explanation why you cannot obtain this information. We may also deny your request for deletion or rectification of your personal information due to statutory provisions, especially those affecting FlightsUnlocked’s accounting, processing of claims, and mandatory data retention, which may prohibit deletion or anonymization.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>How to Contact Us or How to Lodge a Complaint</h1>
FlightsUnlocked Software Corporation controls the processing of personal information on its Platforms. To exercise your rights, or if you have questions about our privacy practices or need assistance with exercising or understanding your privacy choices, please contact our Data Protection Officer.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>FlightsUnlocked Uses Tracking Tools such as Cookies</h1>
We (or our suppliers) use several common tracking tools. We use these tools to collect usage and demographic information about you over time and across different web sites when you use our Platforms. We also have third parties (e.g., advertisers) that collect personal information in this way. Tools we use may include browser cookies. Your browser will store these cookies on your computer in a small file.
 
We and our advertising partners may also use web beacons (single-pixel GIF images). These web beacons are placed in the code of a web page or an email newsletter. When you access a partner site within our mobile applications, we may track your activity on that site.
 
By using the Platforms, you give us your free, unambiguous and informed consent to the use of any of the cookies and tracking tools mentioned in this policy.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>FlightsUnlocked Uses Tracking Tools for the Following Purposes</h1>
(i) To track new visitors to the Platforms.
(ii) To help us recognize your browser as a previous visitor. This includes saving and remembering any preferences that may have been set while your browser was visiting the Platforms, such as language.
(iii) To store your username and password if you are registered with us.
(iv) To make use of cross-device tracking in order to optimize our advertising activities. As part of cross-device tracking, FlightsUnlocked may combine information collected from a particular browser or mobile device with another computer or device linked to the computer or device from which the information was collected. By changing your cookie settings on your device, you can change your cross-device tracking settings for advertising purposes.
(v) To work with online advertising companies to display targeted advertising on our Platforms and third-party platforms that you visit. This targeting may be based on information that you submit on our Platforms or third-party platforms. This targeting may also be based on your activities or behaviors on our Platforms or those of third parties. We may also obtain information about your browsing history from our business partners. To learn more about targeted advertising and how you can accept or refuse (opt-out of) this type of advertisements, we recommend that you visit aboutads.info/choices, youronlinechoices.eu or aboutads.info/appchoices.
(vi) To track when you book on partner websites.
(vii) ;To help improve our website offering and for capacity-planning purposes. We or our third-party service providers may set analytics cookies. These allow us to gather aggregated or segmented information about the types of visitors that access our Platforms and the pages and advertisements that they view. In order to better understand your use of our Platforms, we or our third-party service providers may collect information on such use, including pages visited, links clicked and mouse movements. We do not use this information to personally identify you.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>How You Can Control Tracking Tools and Opt-Out of Online Behavioral Advertising</h1>
Your browser may give you the ability to control cookies. How to do this varies from browser to browser. You should view the help menu on the browser you use for further information. Your opt-out choice is stored in opt-out cookies only in that browser, so you should set your preferences separately for other browsers, computers, or devices you may use. If your browser blocks cookies, your opt-out preferences may not be effective. Deleting browser cookies can remove your opt-out preferences, so you should visit this page periodically to review your preferences. If you block or delete cookies or opt out of online behavioral advertising, not all of the tracking that we have described in this policy will stop.
 
Please also note that opting out of a third-party cookie does not mean that you will no longer receive or be subject to online advertising or marketing. It means that the third-party service from which you opted out will no longer deliver ads tailored to your web preferences and online behavior. To opt out of having your online behavior tracked by FlightsUnlocked for advertising purposes, contact us; this means that FlightsUnlocked will no longer use information derived from your online behavior, collected by FlightsUnlocked or third parties acting on behalf of FlightsUnlocked for the purpose of providing you interest-based advertising from FlightsUnlocked only. Note that opting out of receiving interest-based advertising through the link above does not mean you will no longer receive advertising from FlightsUnlocked or third parties at all; rather it means that most of the advertising that you will see will not be based on your particular interests. In addition, FlightsUnlocked may still collect information for other purposes consistent with this Privacy Policy.
 
We may also use Google Analytics to collect demographic and interest data about you (such as age, gender, and interests), including through Google Analytics Demographics and Interest Reporting. We may use the information collected about you through Google Analytics for Google services such as Remarketing with Google Analytics and Google Display Network Impression Reporting. You can find out more about Google Analytics and how to opt-out by going <a href="https://support.google.com/analytics/answer/2799357" class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">here</a>.. Choices you make are browser- and device-specific. Some aspects of our site use cookies to function. You may not be able to use these features if you set your device to block cookies.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>How FlightsUnlocked Responds to “Do Not Track” Signals</h1>
Some browsers have a “Do Not Track” feature that lets you tell websites that you do not want to have your online activities tracked. These features are not yet uniform, so we are not currently set up to respond to those signals.
 
If you have any questions on our use of tracking tools, please contact us
        </div>
      
        </div>
    </IndexPage>
   
  )
}

export default PrivacyPolicy