import React, { useEffect, useState } from 'react'
import logo from '../../assets/logos/FLogo.svg'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux'

import { changeCurrency} from '../../slices/currencySlice';
import { changeRate } from '../../slices/rateSlice';
import { Link } from 'react-router-dom';


function NavBar(props) {
  const dispatch = useDispatch()
  const curr = useSelector((state) => state.currency)
  

  const [navbarBg, setNavbarBg] = useState(props.home)

  const [currency, setCurrency] = React.useState(curr.state);

  const [currencyRate, setCurrencyRate] = React.useState(useSelector((state) => state.rate));
  const handleChange  = async (event) => {
    setCurrency(event.target.value);
    await dispatch(changeCurrency(event.target.value))
 
    
  };

  useEffect(() => {
   console.log("version 02")
   setCurrency(curr.state)
   console.log(currency);
   getCurrencyRate()
  }, [curr])
  

  const getCurrencyRate = async()=>{
    var oReq = new XMLHttpRequest();
    oReq.addEventListener("load", function () {
       let currencies = JSON.parse((this.responseText))
       console.log(currency);
       var value = currencies.data[currency];
       dispatch(changeRate(value))
       setCurrencyRate(value)
    });
    oReq.open("GET", "https://api.freecurrencyapi.com/v1/latest");
    oReq.setRequestHeader("apikey", "i5IxZQItY8Wosdb6mzJGoX0Qa5CDZBu2iRHH1Tun");
    oReq.send();
  }

  const changeBackground = () => {
    
    if (window.scrollY >= 66) {
      setNavbarBg(true)
    } else {
      setNavbarBg(props.home)
    }
  }

  useEffect(() => {
    changeBackground()


    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })


  return (
    <div className={` z-50 top-0 fixed w-full h-[100px] flex items-center bg-[#000] overflow-hidden	 ${navbarBg}`}>
                <div className="container mx-auto flex items-center justify-between text-white pr-5 sm:pr-0 overflow-hidden	">
        <Link className='my-3 ' to={"/"}><img  height={90} width={185} src={logo}/></Link>
        <div className="flex items-center">
          <div>
         
          </div>
        <FormControl fullWidth>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currency}
       
          onChange={handleChange}
          style={{backgroundColor : "white", width : "100px"}}
        >
          <MenuItem value={"USD"}>USD</MenuItem>
          <MenuItem value={"EUR"}>EUR</MenuItem>
          <MenuItem value={"GBP"}>GBP</MenuItem>
        </Select>
      </FormControl>
        </div>
        </div>
    </div>
  )
}

export default NavBar