import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className=' bg-[#000] w-full   flex flex-col md:flex-row items-center py-8'>
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-white my-auto">
        <div>
            <h1 className='text-sm'>Copyright &copy; FlightsUnlocked.com</h1>
        </div>
        <div className='flex flex-col md:flex-row  mt-10 md:mt-0'>
            <Link to="/privacy"><h1 className='mx-2 my-1 text-sm'>Privacy Policy</h1></Link>
            <Link to="/terms"><h1 className='mx-2 my-1 text-sm'>Terms & Conditions</h1></Link>
            <Link to="/about"><h1 className='mx-2 my-1 text-sm'>About Us</h1></Link>
            <Link to="/contact"><h1 className='mx-2 my-1 text-sm'>Contact Form</h1></Link>
        </div>
        </div>
      
    </div>
  )
}

export default Footer