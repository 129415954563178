import React from 'react'
import { Link } from 'react-router-dom'
import avis from '../../src/assets/logos/avis.svg'
import emirates from '../../src/assets/logos/emirates.svg'
import qantas from '../../src/assets/logos/qantas.svg'
import kayak from '../../src/assets/logos/kayak.png'
import hotelTravel from '../../src/assets/logos/hoteltravel.png'
import tripAdvisor from '../../src/assets/logos/TripAdvisor.svg'

function PartnersSection() {
  return (
    <div className='pb-12'>
    <div className="container mx-auto">
        <h1 className='text-center text-[30px] font-bold my-12'>OUR <span className='text-[orange]'> PARTNERS</span></h1>

        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 items-center w-4/5 mx-auto ">
            <Link to="#"><img className='mx-auto' width={150} src={avis}/></Link>
            <Link to="#"><img className='mx-auto' width={150} src={emirates}/></Link>
            <Link to="#"><img className='mx-auto' width={150} src={qantas}/></Link>
            <Link to="#"><img className='mx-auto' width={150} src={kayak}/></Link>
            <Link to="#"><img className='mx-auto' width={150} src={hotelTravel}/></Link>
            <Link to="#"><img className='mx-auto' width={150} src={tripAdvisor}/></Link>
        </div>
    </div>
    </div>
  )
}

export default PartnersSection