import React from 'react'


function DestinationCard(props) {
  return (
    <div className='rounded-xl overflow-hidden shadow zoom cursor-pointer'>
        <img alt={props.destination} src={props.img} className="h-[200px] w-full"/>
        <div className='flex justify-between p-3 bg-white'>
            <h1 className='font-bold'>{props.destination}</h1>
            <h1 className=' font-bold'>{props.price}</h1>
        </div>
    </div>
  )
}

export default DestinationCard