import React from 'react'

function TimeLineHeader(props) {
  return (
    <div className='flex items-center my-3'>
        <div className="circle h-[40px] w-[40px] text-xl bg-[#1E88E5] rounded-full flex items-center justify-center text-white font-bold mr-3">{props.nbr}</div>
        <div className="title flex flex-col items-start">
            <h1 className='text-xl'>{props.title}</h1>
            <p className='text-slate-400 text-sm'>{props.description}</p>
        </div>
    </div>
  )
}

export default TimeLineHeader