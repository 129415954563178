import React, { useEffect } from 'react'
import IndexPage from '../components/Layout/IndexPage'

function About() {

  useEffect(() => {
    document.title = 'FlightsUnlocked | About Us';
  }, []);
  return (
    <IndexPage>
               <div className='min-h-[89vh]  bg-[#f0f0f0] py-20'>
        <div className="mt-12 container mx-auto">
        <h1 className='text-center text-[40px] my-5 font-bold text-[#1E88E5]'>About us</h1>
        <h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>FlightsUnlocked is the marketplace for travel</h1>
<h1 className='my-5 text-[15px] text-[#000]'>Every day, tens of thousands of people roam the internet and hundreds of flight aggregators to find the special price for their new holiday. 
 
For these people, what was sure to be an exciting and promising vacation suddenly becomes an expensive waste. And yet, on the flip side, there are always travelers looking to score a last-minute deal, whether for a weekend getaway or a spontaneous road trip. <br></br><br></br>
 
That's where we, FlightsUnlocked, come in. Why don't we create the best flight finder utilising our special insider partnerships to get the BEST prices for our users? And the rest is history.<br></br><br></br> 
 
But what we do isn’t the only thing that makes us unique, it’s also how we do it. Our automated validation system verifies the authenticity of every single flight on our website. Our unique best-price guarantee also refreshes the prices automatically upon getting a lower price from our supplier. Did you book and we dropped the price? You get refunded automtically the difference.<br></br><br></br> 
 
For buyers, this process guarantees that what you see is what you get, and all the details are instantly available upon booking. And for our suppliers, FlightsUnlocked’s secure payment vault ensures that the money from your sale will always make it into your pocket.<br></br><br></br>
 
When you book a flight on FlightsUnlocked, your information is instantly passed onto our Airline partner so they are able to help you directly with your booking.
 
<h1 className='my-5 text-[15px] font-bold text-[#1E88E5]'>Your dream flights, UNLOCKED.</h1></h1>
        </div>
      
        </div>
    </IndexPage>
  )
}

export default About