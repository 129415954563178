import React from 'react'

function FormHeader(props) {
  return (
    <div className='mb-5 mt-8'>
        <h1 className='uppercase text-xl mb-2 text-center md:text-start'>{props.title}</h1>
        <hr />
    </div>
  )
}

export default FormHeader