import React from 'react'
import Footer from './Footer'
import NavBar from './NavBar'

function IndexPage(props) {
  return (
    <div>
        <NavBar home={props.home}/>
        <div>
            {props.children}
        </div>
        <Footer />
    </div>
  )
}

export default IndexPage