import { Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TimeLineContent from '../components/TimeLineContent'
import TimeLineElement from '../components/TimeLineElement'
import TimeLineHeader from '../components/TimeLineHeader'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Warning from '../components/Warning'
import { Navigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import { all } from 'axios'
import CheckIcon from '@mui/icons-material/Check';
import IndexPage from '../components/Layout/IndexPage'
import { useSelector } from 'react-redux'



function BookingInfos() {
  const [age, setAge] = React.useState("");
  const [value, setValue] = useState(null)

  const [checks, setChecks] = useState(["Low fares", "Simple and fast booking", "24/7 customer service", "We are a member of IATA"])

  const [flightInfos, setFlightInfos] = useState(null)
  const [bookDetails, setBookDetails] = useState(null)
  const [totalPrice, setTotalPrice] = useState(null)
  const [passengers, setPassengers] = useState(null)
  const [persons, setPersons] = useState(null)
  const [passengerInfos, setPassengerInfos] = useState({title : "", lastName : "",firstName : "", phone : "", email: "", dob : null, gender: ""})

  const [allPassengersData, setAllPassengersData] = useState([])

  const [billingInfos, setBillingInfos] = useState({firstAddress : "", secondAddress : "", city: "", state: "", zipCode : ""})
  const [paymentInfos, setPaymentInfos] = useState({cardNumber : "",nameOnCard : "", expMonth : "", expYear : "",ccv : ""})



  const rate = useSelector((state) => state.rate)
  const [currencyRate, setCurrencyRate] = React.useState(useSelector((state) => state.rate));

  const curr = useSelector((state) => state.currency)
  const [currency, setCurrency] = React.useState(useSelector((state) => state.currency));

  
  useEffect(() => {
    setCurrency(curr)
    setCurrencyRate(rate.state)
    
  }, [rate])
  
  useEffect(() => {
    document.title = 'Booking';
  }, []);


  const location = useLocation()
  useEffect(() => {
    setFlightInfos(location.state.flight)
    setBookDetails(location.state.booking)
    setTotalPrice(location.state.total)
    setPassengers(location.state.passengers)
    setPersons(location.state.persons)
    setAllPassengersData(...allPassengersData, Array.apply(null, Array(location.state.passengers)).map((val,idx) => passengerInfos))

  }, [location])

  useEffect(() => {
    console.log(allPassengersData);
  }, [allPassengersData])


  const submitBooking = (e)=>{
    e.preventDefault()
alert(JSON.stringify(allPassengersData));

alert(JSON.stringify(billingInfos));

alert(JSON.stringify(paymentInfos));    
  }
  
  if (flightInfos == null) return null
  return (
    <IndexPage>
    <div className='bg-[#F0F0F0] min-h-[100vh] py-20 mt-10 px-3'>

    <form onSubmit={(e)=>submitBooking(e)} className='container flex flex-col lg:flex-row mx-auto '>
      <div className="lg:w-3/4">
        <TimeLineElement>
          <TimeLineHeader nbr="1" title="Passenger Details" description="Please fill in the details of the passengers who are going to fly."/>
          <TimeLineContent>
            <Warning title="Double check your personal details" content="If the details are not correct, the passenger might be denied boarding. Often you will then have to buy a new (more expensive) ticket or pay extra to make changes."/>
           { Array.apply(null, Array(passengers)).map((val,idx) => 
            <div className='mt-5' key={idx}>
            <h1 className='text-xl lea underline mb-5'>Passenger#{idx+1}</h1>
            <div className='grid grid-cols-12 gap-4 w-full mb-10'>
               <FormControl className='col-span-4 md:col-span-2'>
                <InputLabel id="demo-simple-select-label">Title</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={allPassengersData[idx].title}
                required
                onChange={(e)=>{
                  let newArr = [...allPassengersData]
                  let item = {
                    ...allPassengersData[idx],
                    title: e.target.value
                }
                newArr[idx] = item
                  setAllPassengersData(newArr)
                }}
                style={{backgroundColor : "white"}}
                label="Title"
             
                >
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>

                  <MenuItem value="Miss">Miss</MenuItem>

                  <MenuItem value="Master">Master</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>

                  <MenuItem value="Prof.">Prof.</MenuItem>


                
                </Select>
               </FormControl>
               <TextField required className='col-span-12 md:col-span-5' id="outlined-basic" label="First name" variant="outlined" style={{backgroundColor : "white"}}
                value={allPassengersData[idx].firstName}
            
                onChange={(e)=>{
                  let newArr = [...allPassengersData]
                  let item = {
                    ...allPassengersData[idx],
                    firstName: e.target.value
                }
                newArr[idx] = item
                  setAllPassengersData(newArr)
                }}/>
               <TextField required className='col-span-12 md:col-span-5' id="outlined-basic" label="Last name" variant="outlined" style={{backgroundColor : "white"}}
                value={allPassengersData[idx].lastName}
            
                onChange={(e)=>{
                  let newArr = [...allPassengersData]
                  let item = {
                    ...allPassengersData[idx],
                    lastName: e.target.value
                }
                newArr[idx] = item
                  setAllPassengersData(newArr)
                }}/>
            </div>
            <div className='grid md:grid-cols-3 gap-4 w-full mb-10'>
               <TextField required    id="outlined-basic" label="Phone" variant="outlined" style={{backgroundColor : "white"}}
                value={allPassengersData[idx].phone}
            
                onChange={(e)=>{
                  let newArr = [...allPassengersData]
                  let item = {
                    ...allPassengersData[idx],
                    phone: e.target.value
                }
                newArr[idx] = item
                  setAllPassengersData(newArr)
                }}/>
               <TextField  required  id="outlined-basic" label="Email" variant="outlined" style={{backgroundColor : "white"}}
                value={allPassengersData[idx].email}
            
                onChange={(e)=>{
                  let newArr = [...allPassengersData]
                  let item = {
                    ...allPassengersData[idx],
                    email: e.target.value
                }
                newArr[idx] = item
                  setAllPassengersData(newArr)
                }}/>

            </div>
            <div className='grid md:grid-cols-3 gap-4 w-full'>
              <LocalizationProvider  dateAdapter={AdapterDayjs}>
               <DatePicker
                 label="Date of birth"
                 value={allPassengersData[idx].dob}
                 required
                 onChange={(e)=>{
                   let newArr = [...allPassengersData]
                   let item = {
                     ...allPassengersData[idx],
                     dob: e
                 }
                 newArr[idx] = item
                   setAllPassengersData(newArr)
                 }}
               
                 renderInput={(params) => <TextField style={{backgroundColor : "white"}} {...params} />}
                 
               />
              </LocalizationProvider>
              <FormControl >
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                label="Gender"
                value={allPassengersData[idx].gender}
            
                onChange={(e)=>{
                  let newArr = [...allPassengersData]
                  let item = {
                    ...allPassengersData[idx],
                    gender: e.target.value
                }
                newArr[idx] = item
                  setAllPassengersData(newArr)
                }}                 style={{backgroundColor : "white"}}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
            
                </Select>
               </FormControl>

            </div>
            </div>)}


         




            
          </TimeLineContent>
        </TimeLineElement>



        <TimeLineElement>
          <TimeLineHeader nbr="2" title="Billing Information" description="Please fill in the details of the owner of the credit card."/>
          <TimeLineContent>
            <div className='grid md:grid-cols-2 gap-4 w-full mb-10'>
               <TextField required value={billingInfos.firstAddress} onChange={(e)=>setBillingInfos({...billingInfos, firstAddress : e.target.value})}  id="outlined-basic" label="Billing Address 1" variant="outlined" style={{backgroundColor : "white"}}/>
               <TextField required value={billingInfos.secondAddress} onChange={(e)=>setBillingInfos({...billingInfos, secondAddress : e.target.value})}    id="outlined-basic" label="Billing Address 2" variant="outlined" style={{backgroundColor : "white"}}/>
            </div>
            <div className='grid md:grid-cols-3 gap-4 w-full'>
               <TextField required value={billingInfos.city} onChange={(e)=>setBillingInfos({...billingInfos, city : e.target.value})}    id="outlined-basic" label="City" variant="outlined" style={{backgroundColor : "white"}}/>
               <TextField required value={billingInfos.state} onChange={(e)=>setBillingInfos({...billingInfos, state : e.target.value})}    id="outlined-basic" label="State" variant="outlined" style={{backgroundColor : "white"}}/>
               <TextField required  value={billingInfos.zipCode} onChange={(e)=>setBillingInfos({...billingInfos, zipCode : e.target.value})}   id="outlined-basic" label="Zip Code" variant="outlined" style={{backgroundColor : "white"}}/>
            </div>
      
          </TimeLineContent>
        </TimeLineElement>




        <TimeLineElement>
          <TimeLineHeader nbr="3" title="Payment Information" description="Please fill in the details of the owner of the credit card."/>
          <TimeLineContent>
            <div className='grid grid-cols-1 gap-4 w-full mb-10'>
              <TextField required value={paymentInfos.nameOnCard} onChange={(e)=>setPaymentInfos({...paymentInfos, nameOnCard : e.target.value})}     id="outlined-basic" label="Name on card" variant="outlined" style={{backgroundColor : "white"}}/>
            </div>
            <div className='grid md:grid-cols-2 gap-4 w-full mb-10'>
               <TextField required value={paymentInfos.cardNumber} onChange={(e)=>setPaymentInfos({...paymentInfos, cardNumber : e.target.value})}    id="outlined-basic" label="Card number" variant="outlined" style={{backgroundColor : "white"}}/>

            </div>
            <div className='grid grid-cols-8 gap-4 w-full '>
              <div className='col-span-4'>
              <p className='mb-1'>Experation date</p>
              <div className=' grid grid-cols-2 gap-4'>
              <TextField required value={paymentInfos.expMonth} onChange={(e)=>setPaymentInfos({...paymentInfos, expMonth : e.target.value})}   id="outlined-basic" label="MM" variant="outlined" style={{backgroundColor : "white"}}/>
               <TextField required value={paymentInfos.expYear} onChange={(e)=>setPaymentInfos({...paymentInfos, expYear : e.target.value})}  id="outlined-basic" label="Year" variant="outlined" style={{backgroundColor : "white"}}/>
              </div>
              </div>
              <div className='col-span-3 md:col-span-1'>
              <p className='mb-1 whitespace-nowrap'>Security code</p>
               <TextField required value={paymentInfos.ccv} onChange={(e)=>setPaymentInfos({...paymentInfos, ccv : e.target.value})}   id="outlined-basic" label="CCV" variant="outlined" style={{backgroundColor : "white"}}/>

              </div>
           

            </div>
      
          </TimeLineContent>
        </TimeLineElement>

        <div className="mt-10 rounded-xl overflow-hidden shadow">
        <Warning title ="PLEASE NOTE!" content="We would like to point out the tightened and more stringent requirements for your passport and the special treatment of your personal details that apply to all passengers travelling from, to and through the USA. Furthermore, infants and children need to be have their own passport! You can find all relevant information in the booking terms and conditions (article 6, paragraph 6). We recommend that you carefully read this information. Since the requirements vary per country, we advise passengers who have a foreign passport to contact the American embassy or consulate."/>

        </div>

        {/* <div className='flex justify-end'>
          <Button className='bookBtn'>Book Now</Button>
        </div> */}
      </div>


   

      <div className='lg:w-1/4  zIndex-1000 mt-10 lg:mt-0 '>
        <div className='rounded-xl overflow-hidden shadow'>
        <p className='text-xl bg-[#1E88E5] p-3 text-center text-white font-bold'>Your flight details</p>
        <div className='py-3 px-5 md:px-6 bg-white'>
            <div className='text-center my-2'>
              <p className='text-slate-400'> {flightInfos?.fromCity} ({flightInfos?.fromIATA.IATA_code} )</p>
              <p className='text-slate-400'>{flightInfos?.toCity}  ({flightInfos?.toIATA.IATA_code} )</p>
              <p className='text-slate-400'>Departure: {moment(flightInfos?.depDate).format('lll')}</p>
              {flightInfos?.tripType == "round" &&<p className='text-slate-400'>Return: {moment(flightInfos?.returnDate).format('lll')}</p>}
            </div>

            {persons?.adultNbr > 0 &&
            <div className="flex justify-between items-center border-t-2 py-2">
              <p className='text-lg text-slate-400'>+12yrs</p>
              <p className='text-lg text-slate-400'>{persons?.adultNbr}</p>
            </div>}
            
            {persons?.kidsNbr > 0 &&
            <div className="flex justify-between items-center border-t-2 py-2">
              <p className='text-lg text-slate-400'>2-12yrs</p>
              <p className='text-lg text-slate-400'>{persons?.kidsNbr}</p>
            </div>}
            
            {persons?.babiesNbr > 0 &&
            <div className="flex justify-between items-center border-t-2 py-2">
              <p className='text-lg text-slate-400'>0-2yrs</p>
              <p className='text-lg text-slate-400'>{persons?.babiesNbr}</p>
            </div>}
            

            {bookDetails?.map((detail,idx)=>
            <div className="flex justify-between items-center border-t-2 py-2" key={idx}>
              <p className='text-lg text-slate-400'>{detail.item}</p>
              <p className='text-lg text-slate-400'>{parseFloat((detail.value * currencyRate).toFixed(2))} <span className='text-xs'>{currency.state}</span></p>
            </div>
            )}

        

            <div className="flex justify-between items-center border-t-2 py-2">
              <p className='text-xl '>TOTAL COST</p>
              <p className='text-xl'>{parseFloat((totalPrice * currencyRate).toFixed(2))} <span className='text-xs'>{currency.state}</span></p>
            </div>

            <div className='my-3'>
              <Button type='submit' className='bookBtn orangeBtn'>Book Now</Button>
            </div>
          


        </div>
        </div>



        <div className='rounded-xl overflow-hidden shadow mt-5'>
           <p className='text-xl bg-[#1E88E5] p-3 text-center text-white font-bold'>Save on great deals!</p>
           <div className='py-3 px-5 md:px-6 bg-white'>
            {checks.map((elem)=>
              <div className="flex items-center text-lg my-2">
                <CheckIcon style={{color : "orange",fontSize : "25px"}}/>
                <span className='ml-3'>{elem}</span>
              </div>
            )}
           </div>


     
        </div>
       
      </div>

    </form>
    </div>
    </IndexPage>
  )
}

export default BookingInfos