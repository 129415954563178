import React from 'react'

function BookInfo(props) {
  return (
    <div className='flex justify-between items-center my-3'>
        <h1 className=''>{props.item}</h1>
        <h1 className={`${props.bold && "font-bold"}`}>{props.value}</h1>
    </div>
  )
}

export default BookInfo