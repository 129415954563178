import React from 'react'
import TipCard from './TipCard'

function TipsSection() {
  return (
    <div className='pb-12'>
        <div className="container mx-auto">
            <h1 className='text-center text-[30px] font-bold mt-80 mb-20 md:mt-12 md:mb-12'><span className='text-[orange]'>TIPS</span> AND <span className='text-[orange]'>TRICKS</span>  FOR FINDING THE CHEAPEST FLIGHTS</h1>
            <div className='grid lg:grid-cols-3 gap-4 w-4/5 mx-auto'>
                <TipCard book colored ="Book" title = " your flights early" description="Looking for the cheapest flights? Start searching well in advance of your planned date of departure. Prices tend to increase gradually but will increase dramatically once availability starts to run low; the less availability the higher the prices. Book domestic tickets 3 months in advance and international tickets 6 months in advance to be sure of getting the best deals."/>
                <TipCard combine colored="Combine" title=" low cost fares" description="Low Cost Airlines are popular for their perceived cheaper fares. We will include as many low cost airlines as possible. It can also sometimes be cheaper to combine two one-way discount airfares for a round-trip journey. For example, you might be able to save up to 70% on a trip to Miami by flying Southwest one way and JetBlue the other. Look for one-way prices and combine the two options."/>
                <TipCard keep colored="Keep" title=" your dates flexible" description="More cheap airline fares are available if your travel dates are flexible. On Mondays flights are typically more expensive, with business travelers taking up all the availability. Flights are usually cheaper in the middle of the week or if you have a Saturday night lay-over in the itinerary. Ticket fares increase over school vacation and long weekends but low cost deals could still be available if you book in advance."/>

            </div>
        </div>
    </div>
  )
}

export default TipsSection