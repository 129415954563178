import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import FormHeader from '../components/FormHeader'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';


function AddFlight() {

    const [IATAs, setIATAs] = useState([])
    const [airlines, setAirlines] = useState([])


    const [flightDetails, setFlightDetails] = useState({fromCity : "",fromIATA : null, toCity : "",toIATA : null,tripType : "direct", flightPrice : 0,depDate: null,depArriveDate: null,depAirline : null, depStop : -1,returnDate:null, returnArriveDate: null,returnAirline : null, returnStop : -1, adultNbr : 0,kidsNbr : 0,babiesNbr : 0,depAirNumber : "",returnAirNumber : "",returnFlightClass :"", depFlightClass : "",depTrips : [], returnTrips : [] })

    const [tripDetails, setTripDetails] = useState({fromCity : "",fromIATA : null, toCity :"" ,toIATA : null,depDate: null,depArriveDate: null,airline : null,tripClass : "",airNumber: "", layoverTime : null})

    const [depTrips, setDepTrips] = useState([])
    const [returnTrips, setReturnTrips] = useState([])

    const searchIATA = (txt)=>{
      axios.get(`https://flightsunlocked.com:2096/api/airport/search?text=${txt}`).then((response) => {
        setIATAs(response.data.docs)
      });
      
    }

    const searchAirline = (txt)=>{
      axios.get(`https://flightsunlocked.com:2096/api/airline/search?text=${txt}`).then((response) => {
        setAirlines(response.data.docs)
      });
    }


    const submitFlight = async(e)=>{
      e.preventDefault()

      console.log(flightDetails);
      
      axios.post(`https://flightsunlocked.com:2096/api/flight`, {data : flightDetails}).then((response) => {
        console.log(response.data.data);
        alert(response.data.message)
      });

 
    
    }

    useEffect(() => {

        
      if (flightDetails.depStop > 0) {
        
      
      let arr = Array.apply(null, Array(flightDetails.depStop+1)).map((val,idx) => tripDetails)
     
      setFlightDetails({...flightDetails, depTrips : [...arr]})
      } else setFlightDetails({...flightDetails, depTrips : []})
   
    }, [flightDetails.depStop])

    useEffect(() => {
   
      if (flightDetails.returnStop > 0) {
      
      let arr = Array.apply(null, Array(flightDetails.returnStop+1)).map((val,idx) => tripDetails)
     
      setFlightDetails({...flightDetails, returnTrips : [...arr]})
    } else setFlightDetails({...flightDetails, returnTrips : []})
    }, [flightDetails.returnStop])

    useEffect(() => {
      document.title = 'Submit Flight';
    }, []);
    
    
  return (
    <form onSubmit={(e)=>submitFlight(e)} className='bg-[#F0F0F0] min-h-[100vh]'>
        <div className='mx-auto px-4 md:px-0 md:w-3/5 lg:w-4/5 pt-5'>
              <h1 className='text-[30px] text-center  font-bold text-[#1E88E5]'>New flight</h1>
              <div>
                  <FormHeader title="Flight information"/>
                  <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
                    <TextField required  id="outlined-basic" label="From (city)" value={flightDetails.fromCity} onChange={(e)=>setFlightDetails({...flightDetails,fromCity : e.target.value})} variant="outlined" style={{backgroundColor : "white"}}/>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={IATAs}
                      required
                      getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
                      onChange={(event, value) => setFlightDetails({...flightDetails,fromIATA : value})}
                      // onChange={(e)=>setFlightDetails({...flightDetails,fromIATA : e.target.value})}
                      renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.fromIATA?.IATA_code+ " - "+flightDetails.fromIATA?.airport_name} onChange={(e)=>searchIATA(e.target.value)} label="From (Airport - IATA code)" />}
                    />

                    <TextField required id="outlined-basic" label="To (city)" value={flightDetails.toCity} onChange={(e)=>setFlightDetails({...flightDetails,toCity : e.target.value})} variant="outlined" style={{backgroundColor : "white"}}/>

                    <Autocomplete
                       disablePortal
                       id="combo-box-demo"
                       required
                       options={IATAs}
                       getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
                       onChange={(event, value) => setFlightDetails({...flightDetails,toIATA : value})}
                       renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.toIATA?.IATA_code+ " - "+flightDetails.toIATA?.airport_name} onChange={(e)=>searchIATA(e.target.value)} label="To (Airport - IATA code)" />}
                     />


                  </div>
                  <div className='grid grid-cols-2 gap-4 my-5'>
                    <FormControl fullWidth>
                     <InputLabel id="demo-simple-select-label">Trip</InputLabel>
                     <Select
                     
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     required
                     style={{backgroundColor : "white"}}
                     label="Trip"
                     value={flightDetails.tripType} onChange={(e)=>setFlightDetails({...flightDetails,tripType : e.target.value})}
                     >
                       <MenuItem value={"direct"}>Direct flight</MenuItem>
                       <MenuItem value={"round"}>Round trip</MenuItem>
                   
                     </Select>
                    </FormControl>
                    <TextField required  id="outlined-basic" value={flightDetails.flightPrice} onChange={(e)=>setFlightDetails({...flightDetails,flightPrice : e.target.value})} type="number" label="Price per passenger $" variant="outlined" style={{backgroundColor : "white"}}/>
                  </div>
              </div>




            <div>
                  <FormHeader title="Passengers"/>
                  <div className="grid grid-cols-3 gap-4 my-5">
                    <TextField  InputProps={{ inputProps: { min: 0 } }} required type="number" min value={flightDetails.adultNbr} onChange={(e)=>setFlightDetails({...flightDetails,adultNbr : e.target.value})}  id="outlined-basic" label="12+ yrs" variant="outlined" style={{backgroundColor : "white"}}/>
                    <TextField  InputProps={{ inputProps: { min: 0 } }} required type="number" value={flightDetails.kidsNbr} onChange={(e)=>setFlightDetails({...flightDetails,kidsNbr : e.target.value})}  id="outlined-basic" label="2-12 yrs" variant="outlined" style={{backgroundColor : "white"}}/>
                    <TextField  InputProps={{ inputProps: { min: 0 } }} required type="number" value={flightDetails.babiesNbr} onChange={(e)=>setFlightDetails({...flightDetails,babiesNbr : e.target.value})}  id="outlined-basic" label="0-2 yrs" variant="outlined" style={{backgroundColor : "white"}}/>


                  </div>
            </div>



            <div>
                  <FormHeader title="Departure details"/>
                  <div className="grid md:grid-cols-2 gap-4 my-5">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                              label="Departure date"
                              required
                              minDate={new Date()}
                              value={flightDetails.depDate}
                              onChange={(newValue) => {
                                setFlightDetails({...flightDetails,depDate : newValue});
                              }}
                            />
                          </LocalizationProvider>
                      
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              required
                              minDate={new Date()}
                              renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                              label="Arrive date"
                              value={flightDetails.depArriveDate}
                              onChange={(newValue) => {
                                setFlightDetails({...flightDetails,depArriveDate : newValue});
                              }}
                            />
                          </LocalizationProvider>
                    </div>
                  <div className="grid md:grid-cols-2 gap-4 my-5">
                  
                 
                <FormControl fullWidth>
                     <InputLabel id="demo-simple-select-label">Stops</InputLabel>
                     <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     required
                     style={{backgroundColor : "white"}}
                     label="Trip"
                     value={flightDetails.depStop} onChange={(e)=>setFlightDetails({...flightDetails,depStop : e.target.value})}
                     >
                       <MenuItem value={0}>Direct</MenuItem>
                       <MenuItem value={1}>1</MenuItem>
                       <MenuItem value={2}>2</MenuItem>
                       <MenuItem value={3}>3</MenuItem>
                       <MenuItem value={4}>4</MenuItem>
                     </Select>
                    </FormControl>
              


                  </div>




                
                  <>
                  
                    {flightDetails.depStop == 0 && 
                    <div className="grid md:grid-cols-3 gap-4 my-5">
                       <Autocomplete
                       disablePortal
                       id="combo-box-demo"
                      required
                       options={airlines}
                       getOptionLabel={(option) =>  option.name}
                       onChange={(event, value) => setFlightDetails({...flightDetails,depAirline : value})}
                       renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.depAirline?.name} onChange={(e)=>searchAirline(e.target.value)} label="Airline" />}
                     />
                    <TextField  type="text" required value={flightDetails.depAirNumber} onChange={(e)=>setFlightDetails({...flightDetails,depAirNumber : e.target.value})}  id="outlined-basic" label="Airline or Airplane Flight Number" variant="outlined" style={{backgroundColor : "white"}}/>
                        <FormControl fullWidth>
                         <InputLabel id="demo-simple-select-label">Class</InputLabel>
                         <Select
                         labelId="demo-simple-select-label"
                         id="demo-simple-select"
                         required
                         style={{backgroundColor : "white"}}
                         label="Trip"
                         value={flightDetails.depFlightClass} onChange={(e)=>setFlightDetails({...flightDetails,depFlightClass : e.target.value})}
                         >
                            <MenuItem value={"Economy"}>Economy</MenuItem>
                            <MenuItem value={"Premium Economy"}>Premium Economy</MenuItem>
                            <MenuItem value={"Business"}>Business</MenuItem>
                            <MenuItem value={"First"}>First</MenuItem>
                         </Select>
                        </FormControl>
                    </div>
}
                  </>
                  {flightDetails.depStop > 0 && <>
                   {/* <div className="grid md:grid-cols-1 gap-4 my-5">
                      <TextField  type="text" value={""} onChange={(e)=>setFlightDetails({...flightDetails,babiesNbr : e.target.value})}  id="outlined-basic" label="Total Trip Duration" variant="outlined" style={{backgroundColor : "white"}}/>
                   </div> */}

                   { Array.apply(null, Array(flightDetails.depStop+1)).map((val,idx) => 
                      <div key={idx}>
                           <FormHeader title={`DEPARTURE ROUTE #${idx+1}`}/>
                           <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
                    <TextField   id="outlined-basic" label="From (city)" 
                      value={flightDetails.depTrips[idx]?.fromCity}
                      onChange={(e)=>{
                        let newArr = [...flightDetails.depTrips]
                        let item = {
                          ...flightDetails.depTrips[idx],
                          fromCity: e.target.value
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, depTrips : newArr})

                      }}
                      required
                       variant="outlined" style={{backgroundColor : "white"}}/>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={IATAs}
                      required
                      getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
                     
                      onChange={(event,value)=>{
                        let newArr = [...flightDetails.depTrips]
                        let item = {
                          ...flightDetails.depTrips[idx],
                          fromIATA: value
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, depTrips : newArr})

                      }}
             
                      // onChange={(e)=>setFlightDetails({...flightDetails,fromIATA : e.target.value})}
                      renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.depTrips[idx]?.fromIATA?.IATA_code+ " - "+flightDetails.depTrips[idx]?.fromIATA?.airport_name} onChange={(e)=>searchIATA(e.target.value)} label="From (Airport - IATA code)" />}
                    />

                    <TextField  id="outlined-basic" label="To (city)"
                      value={flightDetails.depTrips[idx]?.toCity}
                      onChange={(e)=>{
                        let newArr = [...flightDetails.depTrips]
                        let item = {
                          ...flightDetails.depTrips[idx],
                          toCity: e.target.value
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, depTrips : newArr})

                      }}
                      required
                       variant="outlined" style={{backgroundColor : "white"}}/>

                    <Autocomplete
                       disablePortal
                       id="combo-box-demo"
                      required
                       options={IATAs}
                       getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
                           
                       onChange={(event,value)=>{
                        let newArr = [...flightDetails.depTrips]
                        let item = {
                          ...flightDetails.depTrips[idx],
                          toIATA: value
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, depTrips : newArr})

                      }}
                       renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.depTrips[idx]?.toIATA?.IATA_code+ " - "+flightDetails.depTrips[idx]?.toIATA?.airport_name} onChange={(e)=>searchIATA(e.target.value)} label="To (Airport - IATA code)" />}
                     />


                  </div>


                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                              label="Departure date"
                              required
                              minDate={new Date()}
                              value={flightDetails.depTrips[idx]?.depDate}
                              onChange={(value)=>{
                                let newArr = [...flightDetails.depTrips]
                                let item = {
                                  ...flightDetails.depTrips[idx],
                                  depDate: value
                              }
                              newArr[idx] = item
                              setFlightDetails({...flightDetails, depTrips : newArr})
        
                              }}
                           
                          
                            />
                          </LocalizationProvider>
                      
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              required
                              minDate={new Date()}
                              renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                              label="Arrive date"
                              value={flightDetails.depTrips[idx]?.depArriveDate}
                              onChange={(value)=>{
                                let newArr = [...flightDetails.depTrips]
                                let item = {
                                  ...flightDetails.depTrips[idx],
                                  depArriveDate: value
                              }
                              newArr[idx] = item
                              setFlightDetails({...flightDetails, depTrips : newArr})
        
                              }}
                            />
                          </LocalizationProvider>

                          {/* <TextField  id="outlined-basic" label="Flight duration" value={flightDetails.toCity} onChange={(e)=>setFlightDetails({...flightDetails,toCity : e.target.value})} variant="outlined" style={{backgroundColor : "white"}}/> */}

                          <TextField  disabled={flightDetails.depStop == idx} id="outlined-basic" label="Layover time"  
                              value={flightDetails.depTrips[idx]?.layoverTime}
                              onChange={(e)=>{
                                let newArr = [...flightDetails.depTrips]
                                let item = {
                                  ...flightDetails.depTrips[idx],
                                  layoverTime: e.target.value
                              }
                              newArr[idx] = item
                              setFlightDetails({...flightDetails, depTrips : newArr})
        
                              }}
                              required
                             variant="outlined" style={{backgroundColor : "white"}}/>
                    </div>



                      <div className="grid  lg:grid-cols-3 gap-4 my-5">
                           
                  <Autocomplete
                       disablePortal
                       id="combo-box-demo"
                       required
                       options={airlines}
                       getOptionLabel={(option) =>  option.name}
                   
                       onChange={(e,v)=>{
                         let newArr = [...flightDetails.depTrips]
                         let item = {
                           ...flightDetails.depTrips[idx],
                           airline: v
                       }
                       newArr[idx] = item
                       setFlightDetails({...flightDetails, depTrips : newArr,depAirline : newArr[0].airline})
                       }}
                       renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={depTrips[idx]?.airline?.name} onChange={(e)=>searchAirline(e.target.value)} label="Airline" />}
                     />
                      <TextField  type="text"   value={flightDetails.depTrips[idx]?.airNumber}
                      onChange={(e)=>{
                        let newArr = [...flightDetails.depTrips]
                        let item = {
                          ...flightDetails.depTrips[idx],
                          airNumber: e.target.value
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, depTrips : newArr,depAirNumber : newArr[0].airNumber})
                      }}  
                      required
                      id="outlined-basic" label="Airline or Airplane Flight Number" variant="outlined" style={{backgroundColor : "white"}}/>
                        <FormControl fullWidth>
                         <InputLabel id="demo-simple-select-label">Class</InputLabel>
                         <Select
                         labelId="demo-simple-select-label"
                         id="demo-simple-select"
                         required
                         style={{backgroundColor : "white"}}
                         label="Trip"
                         value={flightDetails.depTrips[idx]?.tripClass}
                      onChange={(e)=>{
                        let newArr = [...flightDetails.depTrips]
                        let item = {
                          ...flightDetails.depTrips[idx],
                          tripClass: e.target.value
                          
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, depTrips : newArr, depFlightClass : newArr[0].tripClass})
                  

                      }}  
                         >
                            <MenuItem value={"Economy"}>Economy</MenuItem>
                            <MenuItem value={"Premium Economy"}>Premium Economy</MenuItem>
                            <MenuItem value={"Business"}>Business</MenuItem>
                            <MenuItem value={"First"}>First</MenuItem>
                         </Select>
                        </FormControl>
                      </div>
                      </div>
                   )}


                  
                  </>
                  }
            </div>






           {flightDetails.tripType === "round" && 
          <div>
          <FormHeader title="Return details"/>
          <div className="grid md:grid-cols-2 gap-4 my-5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                      label="Return date"
                      required
                      minDate={new Date()}
                      value={flightDetails.returnDate}
                      onChange={(newValue) => {
                        setFlightDetails({...flightDetails,returnDate : newValue});
                      }}
                    />
                  </LocalizationProvider>
              
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      required
                      minDate={new Date()}
                      renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                      label="Return Arrive date"
                      value={flightDetails.returnArriveDate}
                      onChange={(newValue) => {
                        setFlightDetails({...flightDetails,returnArriveDate : newValue});
                      }}
                    />
                  </LocalizationProvider>
            </div>
          <div className="grid md:grid-cols-2 gap-4 my-5">
          
         
        <FormControl fullWidth>
             <InputLabel id="demo-simple-select-label">Stops</InputLabel>
             <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             required
             style={{backgroundColor : "white"}}
             label="Trip"
             value={flightDetails.returnStop} onChange={(e)=>setFlightDetails({...flightDetails,returnStop : e.target.value})}
             >
               <MenuItem value={0}>Direct</MenuItem>
               <MenuItem value={1}>1</MenuItem>
               <MenuItem value={2}>2</MenuItem>
               <MenuItem value={3}>3</MenuItem>
               <MenuItem value={4}>4</MenuItem>
             </Select>
            </FormControl>
      


          </div>




        
          <>
          
            {flightDetails.returnStop == 0 && 
            <div className="grid md:grid-cols-3 gap-4 my-5">
               <Autocomplete
               disablePortal
               id="combo-box-demo"
               required
               options={airlines}
               getOptionLabel={(option) =>  option.name}
               onChange={(event, value) => setFlightDetails({...flightDetails,returnAirline : value})}
               renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.returnAirline?.name} onChange={(e)=>searchAirline(e.target.value)} label="Airline" />}
             />
            <TextField  type="text" value={flightDetails.returnAirNumber} onChange={(e)=>setFlightDetails({...flightDetails,returnAirNumber : e.target.value})}  id="outlined-basic" label="Airline or Airplane Flight Number" variant="outlined" style={{backgroundColor : "white"}}/>
                <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">Class</InputLabel>
                 <Select
                 labelId="demo-simple-select-label"
                 id="demo-simple-select"
                 required
                 style={{backgroundColor : "white"}}
                 label="Trip"
                 value={flightDetails.returnFlightClass} onChange={(e)=>setFlightDetails({...flightDetails,returnFlightClass : e.target.value})}
                 >
                    <MenuItem value={"Economy"}>Economy</MenuItem>
                    <MenuItem value={"Premium Economy"}>Premium Economy</MenuItem>
                    <MenuItem value={"Business"}>Business</MenuItem>
                    <MenuItem value={"First"}>First</MenuItem>
                 </Select>
                </FormControl>
            </div>
}
          </>
          {flightDetails.returnStop > 0 && <>
           {/* <div className="grid md:grid-cols-1 gap-4 my-5">
              <TextField  type="text" value={""} onChange={(e)=>setFlightDetails({...flightDetails,babiesNbr : e.target.value})}  id="outlined-basic" label="Total Trip Duration" variant="outlined" style={{backgroundColor : "white"}}/>
           </div> */}

           { Array.apply(null, Array(flightDetails.returnStop+1)).map((val,idx) => 
              <div key={idx}>
                   <FormHeader title={`RETURN ROUTE #${idx+1}`}/>
                   <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
            <TextField   id="outlined-basic" label="From (city)" 
              value={flightDetails.returnTrips[idx]?.fromCity}
              onChange={(e)=>{
                let newArr = [...flightDetails.returnTrips]
                let item = {
                  ...flightDetails.returnTrips[idx],
                  fromCity: e.target.value
              }
              newArr[idx] = item
              setFlightDetails({...flightDetails, returnTrips : newArr})
              }}
              required
               variant="outlined" style={{backgroundColor : "white"}}/>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={IATAs}
              required
              getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
             
              onChange={(event,value)=>{
                let newArr = [...flightDetails.returnTrips]
                let item = {
                  ...flightDetails.returnTrips[idx],
                  fromIATA: value
              }
              newArr[idx] = item
              setFlightDetails({...flightDetails, returnTrips : newArr})
              }}
     
              // onChange={(e)=>setFlightDetails({...flightDetails,fromIATA : e.target.value})}
              renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.returnTrips[idx]?.fromIATA?.IATA_code+ " - "+flightDetails.returnTrips[idx]?.fromIATA?.airport_name} onChange={(e)=>searchIATA(e.target.value)} label="From (Airport - IATA code)" />}
            />

            <TextField  id="outlined-basic" label="To (city)"
              value={flightDetails.returnTrips[idx]?.toCity}
              onChange={(e)=>{
                let newArr = [...flightDetails.returnTrips]
                let item = {
                  ...flightDetails.returnTrips[idx],
                  toCity: e.target.value
              }
              newArr[idx] = item
              setFlightDetails({...flightDetails, returnTrips : newArr})
              }}
              required
               variant="outlined" style={{backgroundColor : "white"}}/>

            <Autocomplete
               disablePortal
               id="combo-box-demo"
               required
               options={IATAs}
               getOptionLabel={(option) =>  option.IATA_code+ " - "+option.airport_name}
                   
               onChange={(event,value)=>{
                let newArr = [...flightDetails.returnTrips]
                let item = {
                  ...flightDetails.returnTrips[idx],
                  toIATA: value
              }
              newArr[idx] = item
              setFlightDetails({...flightDetails, returnTrips : newArr})
              }}
               renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.returnTrips[idx]?.toIATA?.IATA_code+ " - "+flightDetails.returnTrips[idx]?.toIATA?.airport_name} onChange={(e)=>searchIATA(e.target.value)} label="To (Airport - IATA code)" />}
             />


          </div>


            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                      label="Departure date"
                      required
                      minDate={new Date()}
                      value={flightDetails.returnTrips[idx]?.depDate}
                      onChange={(value)=>{
                        let newArr = [...flightDetails.returnTrips]
                        let item = {
                          ...flightDetails.returnTrips[idx],
                          depDate: value
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, returnTrips : newArr})
                      }}
                   
                  
                    />
                  </LocalizationProvider>
              
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      required
                      minDate={new Date()}
                      renderInput={(props) => <TextField {...props}   style={{backgroundColor : "white"}}/>}
                      label="Arrive date"
                      value={flightDetails.returnTrips[idx]?.depArriveDate}
                      onChange={(value)=>{
                        let newArr = [...flightDetails.returnTrips]
                        let item = {
                          ...flightDetails.returnTrips[idx],
                          depArriveDate: value
                      }
                      newArr[idx] = item
                      setFlightDetails({...flightDetails, returnTrips : newArr})
                      }}
                    />
                  </LocalizationProvider>

                  {/* <TextField  id="outlined-basic" label="Flight duration" value={flightDetails.toCity} onChange={(e)=>setFlightDetails({...flightDetails,toCity : e.target.value})} variant="outlined" style={{backgroundColor : "white"}}/> */}

                  <TextField  disabled={flightDetails.returnStop == idx} id="outlined-basic" label="Layover time"  
                    value={flightDetails.returnTrips[idx]?.layoverTime}
                    onChange={(e)=>{
                      let newArr = [...flightDetails.returnTrips]
                      let item = {
                        ...flightDetails.returnTrips[idx],
                        layoverTime: e.target.value
                    }
                    newArr[idx] = item
                    setFlightDetails({...flightDetails, returnTrips : newArr})
                    }}
                    required
                     variant="outlined" style={{backgroundColor : "white"}}/>
            </div>



              <div className="grid  lg:grid-cols-3 gap-4 my-5">
                   
          <Autocomplete
               disablePortal
               id="combo-box-demo"
               required
               options={airlines}
               getOptionLabel={(option) =>  option.name}
           
               onChange={(e,v)=>{
                 let newArr = [...flightDetails.returnTrips]
                 let item = {
                   ...flightDetails.returnTrips[idx],
                   airline: v
               }
               newArr[idx] = item
               setFlightDetails({...flightDetails, returnTrips : newArr,returnAirline : newArr[0].airline})
               }}
               renderInput={(params) => <TextField  style={{backgroundColor : "white"}} {...params} value={flightDetails.returnTrips[idx]?.airline?.name} onChange={(e)=>searchAirline(e.target.value)} label="Airline" />}
             />
              <TextField  type="text"   value={flightDetails.returnTrips[idx]?.airNumber}
              onChange={(e)=>{
                let newArr = [...flightDetails.returnTrips]
                let item = {
                  ...flightDetails.returnTrips[idx],
                  airNumber: e.target.value
              }
              
              newArr[idx] = item
              setFlightDetails({...flightDetails, returnTrips : newArr,returnAirNumber : newArr[0].airNumber})
              }}  
              required
              id="outlined-basic" label="Airline or Airplane Flight Number" variant="outlined" style={{backgroundColor : "white"}}/>
                <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">Class</InputLabel>
                 <Select
                 labelId="demo-simple-select-label"
                 id="demo-simple-select"
                 required
                 style={{backgroundColor : "white"}}
                 label="Trip"
                 value={flightDetails.returnTrips[idx]?.tripClass}
                 onChange={(e)=>{
                   let newArr = [...flightDetails.returnTrips]
                   let item = {
                     ...flightDetails.returnTrips[idx],
                     tripClass: e.target.value
                 }
                 newArr[idx] = item
                 setFlightDetails({...flightDetails, returnTrips : newArr,returnFlightClass : newArr[0].tripClass})
                 }}
                 >
                <MenuItem value={"Economy"}>Economy</MenuItem>
                            <MenuItem value={"Premium Economy"}>Premium Economy</MenuItem>
                            <MenuItem value={"Business"}>Business</MenuItem>
                            <MenuItem value={"First"}>First</MenuItem>
                 </Select>
                </FormControl>
              </div>
              </div>
           )}


          
          </>
          }
    </div>

            }

            <h1 className='text-[red] text-xs text-center'>Please double check the data information to make sure is 100% correct before submitting it to us. If any of the details above is incorrect you will NOT be refunded.</h1>

            <div className='w-1/3 flex mx-auto my-8'>
                <Button type='submit' className='bookBtn'>Submit flight +</Button>
            </div>
        </div>
    </form>
  
  )
}

export default AddFlight





