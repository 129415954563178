import React from 'react'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';


function TipCard(props) {
  return (
    <div className='bg-white px-5 py-5 shadow rounded-lg flex flex-col items-center zoom cursor-pointer'>
        <div className='my-3 border p-4 border-2 border-[#2196f3]   rounded-full'>
           {props.book && <LocationOnOutlinedIcon style={{fontSize :"40px" , color :"#2196F3"}}/>}
           {props.combine && <RemoveRedEyeOutlinedIcon style={{fontSize :"40px",color :"#2196F3"}}/>}
           {props.keep && <EventOutlinedIcon style={{fontSize :"40px",color :"#2196F3"}}/>}
        </div>
        <h1 className='text-xl font-bold my-5'><span className='text-[orange]'>{props.colored}</span> {props.title}</h1>
        <p className='text-center text-sm text-[grey]'>{props.description}</p>
    </div>
  )
}

export default TipCard