import React, { useEffect } from 'react'
import DestinationSection from '../components/DestinationSection';
import IndexPage from '../components/Layout/IndexPage';
import PartnersSection from '../components/PartnersSection';
import SearchForm from '../components/SearchForm';
import TipsSection from '../components/TipsSection';



function Home() {

    useEffect(() => {
    document.title = 'FlightsUnlocked | Home';
  }, []);
  return (
    <IndexPage home ="navOpacity">
      <div className='bg-[#F0F0F0]'>

     
        <div className='hero-image overflow-visible '>
        <h1 className='text-hero text-[25px] text-white font-bold  md:hidden text-center top-[150px] left-[50%] whitespace-nowrap'>Your Flights, UNLOCKED</h1>

        <h1 className='text-hero text-[35px] text-white font-bold hidden md:block  left-[50%]   top-[25%] '><span className='text-[32px] font-normal'>Your Flights,</span> UNLOCKED</h1>
          <SearchForm />
        </div>
        <TipsSection />
        <DestinationSection />
        <PartnersSection />
    </div>
    </IndexPage>
    
  )
}

export default Home

