import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FlightCard from '../components/FlightCard'
import IndexPage from '../components/Layout/IndexPage';

function SearchResult() {
  const  location = useLocation()

 
    
  
  useEffect(() => {
    console.log(location.state);

  }, [])

  useEffect(() => {
    document.title = 'FlightsUnlocked | Results';
  }, []);
  
  return (
    <IndexPage>
    <div className='flex flex-col items-center bg-[#F0F0F0] min-h-[100vh] px-4 py-20'>
      
       <div className="container mx-auto mt-10">
  
       {location.state.results?.data ?
        location.state.results.data.map((flight,index)=><FlightCard  key={index} flight={flight} search={location.state?.search}/>)
        :<div className='text-lg text-center mt-10'>
           <h1>No results found</h1>
           <Link to={"/"} className="font-bold underline text-[orange]">Try again</Link>
          </div>}
       </div>
     
       {/* <FlightCard departureDate="15 JAN 16:15" arriveDate="15 Jan 18:00" stop="1 stop" fromIATA="ALG" toIATA="ANB" price="200 $" />
       <FlightCard round/>
       <FlightCard />
       <FlightCard /> */}
    </div>
    </IndexPage>
  )
}

export default SearchResult