import React, { useEffect } from 'react'
import IndexPage from '../components/Layout/IndexPage'

function Terms() {

  useEffect(() => {
    document.title = 'FlightsUnlocked | Terms & Conditions';
  }, []);
  return (
    <IndexPage>
         <div className='min-h-[89vh]  bg-[#f0f0f0] py-20'>
        <div className="mt-12 container mx-auto">
        <h1 className='my-5 text-center text-[40px] font-bold text-[#1E88E5]'>Terms & Conditions</h1>
Please read these terms and conditions of use carefully before accessing, using or obtaining any materials, information, products or services. By accessing the FlightsUnlocked website, mobile or tablet application, or any other feature or other FlightsUnlocked platform (collectively, "Our Website”), you agree to be bound by these terms and conditions ("Terms") and our <a href="/privacy" class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">Privacy Policy</a>. If you do not accept all of these Terms, then you may not use Our Website. In these Terms, "we", "us", "our" and "FlightsUnlocked" refers to FlightsUnlocked Software Corporation, and "you" and "your" refers to you, the user of Our Website.
 
THESE TERMS INCLUDE AN ARBITRATION CLAUSE AND A WAIVER OF YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE LAWSUIT.
 
We may modify these Terms for any reason—at any time—by posting a new version on Our Website; these changes do not affect rights and obligations that arose prior to such changes. Your continued use of Our Website following the posting of modified Terms will be subject to the Terms in effect at the time of your use. Please review these Terms periodically for changes. If you object to any provision of these Terms or any subsequent modifications to these Terms or become dissatisfied with Our Website in any way, your only recourse is to immediately terminate use of Our Website.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>1. Who We Are</h1>
This Website is provided solely to assist customers in gathering travel information, determining the availability of travel-related goods and services, making legitimate reservations or otherwise transacting business with travel suppliers, and for no other purposes. The term "you" refers to the customer visiting the Website and/or booking a reservation through us on this Website, or through our customer service agents.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>2. As a condition of your use of this Website, you warrant that:</h1>
(i) you are at least 18 years of age,
 
(ii) you possess the legal authority to create a binding legal obligation,
 
(iii) you will use this Website in accordance with this Agreement,
 
(iv) you will only use this Website to make legitimate reservations for you or for another person for whom you are legally authorized to act,
 
(v) you will inform such other persons about the terms and conditions that apply to the reservations you have made on their behalf, including all rules and restrictions applicable thereto
 
(vi) all information supplied by you on this Website is true, accurate, current and complete, and
 
(vii) if you have an account, you will safeguard your account information and will supervise and be completely responsible for any use of your account by you and anyone other than you. We retain the right at our sole discretion to deny access to anyone to this Website and the services we offer, at any time and for any reason, including, but not limited to, for violation of this Agreement.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>3. Intellectual Property</h1>
We, along with our corporate affiliates, the Travel Providers and other licensors, own all of the text, images, software, trademarks, service marks and other material contained on Our Website. You will not copy or transmit any of the material except if you are doing so for your personal, non-commercial use. All copyright, trademark and other proprietary rights notices presented on Our Website must appear on all copies you print. Other non-FlightsUnlocked product, service, or company designations on Our Website belong to those respective third parties and may be mentioned in Our Website for identification purposes only. You should contact the appropriate third party for more complete information regarding such designations and their registration status. Your use of and access to Our Website does not grant you any license or right to use any of the marks included on Our Website.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>4. Use of Our Website</h1>
You may only use and register to become a user of Our Website or Trips if you are of sufficient legal age and can enter into binding contracts. If you become a registered user or make a booking resulting in the creation of a Trips account, you are responsible for maintaining the secrecy of your passwords, login and account information. You will be responsible for all use of Our Website by you, anyone using your password and login information (with or without your permission) and anyone who you allow to access your travel itineraries. All information that you provide to us must be accurate and up-to-date. If any of your information changes, you must immediately update it. If you have reason to believe that your account is no longer secure (e.g., loss, theft or unauthorized disclosure or use of your information or computer or mobile device used to access Our Website), you must promptly change your Personal information that is affected.
 
If you decide to have messages or other communications from Our Website sent directly to your mobile device, you are solely responsible for keeping us updated with your current phone number, respectively updating to the latest version of the mobile app, and for any charges incurred by receiving such messages. We will not be liable for information sent to a device that is associated with your outdated mobile phone number or using an outdated mobile app. If you install any software or enable any service that stores information from Our Website on any mobile device or computer, it is your responsibility, prior to transfer or disposal of such device, to remove your information or otherwise disable access to such software or service in order to prevent unauthorized access to your information or account.
 
You may only use Our Website to search for legitimate travel deals; you may not use Our Website to make any false, fraudulent or speculative reservation or any reservation in anticipation of demand. By using Our Website, you agree to comply with laws that apply to the United States and your own country, including laws that apply to exporting technical data.
 
In addition, you agree not to do any of the following without prior express written permission from FlightsUnlocked:
 
(i) access the site with any manual or automated process for any purpose other than your personal use or for inclusion of FlightsUnlocked pages in a search index. Use of any automated system or software to extract data from Our Website (“screen scraping”), for commercial or non-commercial purposes, is prohibited;
 
(ii) violate the restrictions in any robot exclusion headers on Our Website or bypass or circumvent other measures employed to prevent or limit access to Our Website;
 
(iii) deep-link to any portion of Our Website for any purpose;
 
(iv) use any device, software or routine that interferes or attempts to interfere with the normal operation of Our Website or take any action that imposes an unreasonable load on our computer or network equipment;
 
(v) reproduce, duplicate, copy, sell, trade, resell or exploit Our Website;
 
(vi) use any feature of Our Website for any purpose that is unlawful, harmful, or otherwise objectionable or inappropriate, as determined by us;
 
(vii) post or distribute any material on Our Website that violates the rights of any third party or applicable law;
 
(viii) use Our Website to collect or store personal data about others;
 
(ix) use Our Website for any commercial purpose; or
 
(x) transmit any ad or promotional materials on Our Website
 
We may, at our sole discretion, at any time and without advance notice or liability, suspend, terminate or restrict your access to any or all component(s) of Our Website.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>5. Trips</h1>
If you use Trips, you are solely responsible for the travel information and other content that you upload, transmit or share with us or others on or through Trips (collectively, the "Trips Information"), and you represent and warrant that you are not transmitting or sharing Trips Information that you do not have permission to share. It is your job to create backup copies and replace any Trips Information you provide us at your expense.
 
When you provide us with Trips Information or make a booking through FlightsUnlocked, you authorize us to make copies as we deem necessary in order to facilitate the storage and assimilation of the Trips Information. By providing us Trips Information, you represent and warrant that you have the right to give us an irrevocable, perpetual, non-exclusive, transferable, fully-paid, worldwide license (with the right to freely sublicense) to use, copy, modify, reformat, translate, syndicate and distribute that Trips Information that we receive from you for any purpose, including business, commercial, marketing, advertising, or otherwise, and to prepare derivative works of (or incorporate into other works) that Trips Information. You may remove your Trips Information from Trips at any time, but the license that you have granted will remain in effect. You understand that we do not control nor are we responsible for reviewing Trips Information. However, we reserve the right to review, edit, or delete any Trips information or your account at any time.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>6. Your Intellectual Property Rights</h1>
We respect the intellectual property rights of others and we prohibit users from uploading, posting or otherwise transmitting on Our Website (or otherwise through Trips) any materials that violate another person's intellectual property rights. If you believe that your trademark or copyright rights have been violated, please go to our Proprietary Rights Complaint Process, and follow the instructions at that area or send us a written notification to the address disclosed in Section 15.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>7. Warranty Disclaimer</h1>
Our Website, all content and services provided on Our Website and all itineraries that you obtain through Trips are provided on an "as is" and "as available" basis. Our content is largely generated in an automated fashion; errors can and do happen. We usually have many search results, but we are not comprehensive and do not display all available providers and offers. Accordingly, we do not always display the lowest available price. FlightsUnlocked expressly disclaims—to the fullest extent permissible—all warranties of any kind, whether express or implied, including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, and security and accuracy, as well as all warranties arising by usage of trade, course of dealing, or course of performance.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>8. Our Liability Is Limited</h1>
We (together with our officers, directors, employees, representatives, shareholders, affiliates, and providers), to the extent permitted by law, hereby expressly exclude any responsibility and liability for (a) any loss or damages to, or viruses that may infect, your computer equipment or other property as the result of your access to Our Website, your downloading of any content from Our Website or your use of Trips or (b) any injury; death; loss; claim; act of god; accident; delay; or any direct, special, exemplary, punitive, indirect, incidental or consequential damages of any kind (including—without limitation—lost profits or lost savings), whether based in contract, tort, strict liability or otherwise, that arise out of or is in any way connected with: (i) any use of Our Website, Trips or our content; (ii) any failure or delay (including—without limitation—the use of or inability to use any component of this Website for reservations or booking); or (iii) the performance or nonperformance by us or any Travel Provider, even if we have been advised of the possibility of damages to such parties or any other party. Some states or countries do not allow this limitation of liability, so the limitations above may not apply or apply only partially to you.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>9. You Agree to Protect Us</h1>
Subject to these Terms, you will defend, indemnify and hold us and each of our officers, directors, employees and agents harmless from and against any claim, cause of action, liability, expense, loss or demand, including—without limitation—reasonable legal and accounting fees, arising out of, or in any way connected with your breach of these Terms or the agreements made part of these Terms by reference, your breach of any applicable law, and your use of or access to Our Website, Trips or the Intellectual Property.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>10. Arbitration and Waiver</h1>
You agree that by accepting the Terms, you and Company are each waiving the right to trial by jury and the ability to participate in a class action. ANY AND ALL DISPUTES COMPANY OR YOU HAS RELATING IN ANY WAY TO THE SERVICES OR YOUR RELATIONSHIP WITH THE COMPANY (INCLUDING—WITHOUT LIMITATION—WITH RESPECT TO DATA, YOUR INTERACTION WITH THE COMPANY, COMPANY’S ADVERTISEMENTS AND DISCLOSURES, EMAIL AND MOBILE SMS (TEXT) MESSAGES COMPANY SENDS TO YOU, OR THE USE OR DISCLOSURE OF ANY INFORMATION ABOUT YOU), THESE TERMS OF USE, AND PRIVACY POLICY (COLLECTIVELY, “CLAIMS”) WILL BE SUBMITTED TO CONFIDENTIAL ARBITRATION IN THE FEDERAL JUDICIAL DISTRICT IN WHICH YOU RESIDE, EXCEPT TO THE EXTENT THAT YOU HAVE, IN ANY WAY, VIOLATED OR THREATENED TO VIOLATE ANY COMPANY INTELLECTUAL PROPERTY RIGHT. CLAIMS INCLUDE CLAIMS BASED ON CONTRACT, TORT (INCLUDING INTENTIONAL TORT), FRAUD, AGENCY, YOUR OR THE COMPANY’S NEGLIGENCE, STATUTORY OR REGULATORY PROVISIONS, OR ANY OTHER SOURCE OF LAW.
 
If you intend to seek arbitration, you must first send to the Company, by certified mail, a written Notice of Dispute ("Notice"). The Notice shall describe the nature and basis of the claim or disputes and the specific relief sought. If you and the Company cannot reach an agreement to resolve the claim within thirty (30) days after the Notice is received, either party may commence arbitration. All arbitrations required by these Terms will be conducted under the Commercial Arbitration rules of the American Arbitration Association. The arbitrator’s award is binding and may be entered in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration brought under, or with respect to, Claims is to be joined to an arbitration involving any other party subject to these Terms, whether through class arbitration proceedings or otherwise. Unless you and the Company agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative, private attorney general or class proceeding.
 
For arbitration claims that you assert against Company in accordance with this section (but not for any arbitration claim against you), Company will pay all of your administrative, hearing, and arbitrator’s fees and costs for the arbitration (but not the fees, expenses, and costs of your lawyers, experts, or witnesses) in excess of any filing fee you would have been required to pay in order to file the claim as a lawsuit in a state or federal court (whichever is greater) in the judicial district in which you reside. Unless unlawful, Company will pay its—and you will pay your—lawyers’, experts’, and witness’ fees, expenses, and costs with respect to all claims. The arbitrator may award money or equitable relief in favor of only the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. Similarly, an arbitration award and any judgment confirming it apply only to that specific case; it cannot be used in any other case except to enforce the award itself. To reduce the time and expense of the arbitration, the arbitrator will not provide a statement of reasons for his or her award unless requested to do so by all parties.
 
TO THE EXTENT PERMITTED BY LAW, YOU WILL NOT AGREE TO ACT AS A REPRESENTATIVE OR A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT AGAINST COMPANY IN ANY COURT, OR IN ARBITRATION, WITH RESPECT TO ANY CLAIMS. THIS SECTION (CONFIDENTIAL ARBITRATION) WILL SURVIVE THE TERMINATION OF THE RELATIONSHIP BETWEEN YOU AND COMPANY FOR ANY AND ALL DISPUTES YOU OR THE COMPANY HAS RELATING—IN ANY WAY—TO THE SERVICES OR YOUR RELATIONSHIP WITH THE COMPANY.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>11. Air Carrier Rules</h1>
The circumvention of an air carrier's rules, including practices such as back-to-back ticketing (purchasing two or more tickets with overlapping travel dates in order to circumvent minimum stay requirements) and hidden-city ticketing (purchasing tickets including segments which the purchaser does not intend to use in order to circumvent an air carrier's pricing structure), is prohibited by many air carriers. The use of prohibited ticketing practices may result in the air carrier taking actions, including the cancellation of the ticket, denied boarding, revocation of frequent flier miles and other benefits, additional charges to the purchaser's credit card, additional charges collected at the airport, or future invoicing.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>12. General Requirements</h1>
We may change the site and these Terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these Terms. Your continued use of the site constitutes your acceptance of any changes to these Terms and any changes will supersede all previous versions of the Terms. Unless otherwise specified herein, all changes to these Terms apply to all users, including those enrolled before the date the changes take effect. Furthermore, we may terminate this agreement with you under these Terms at any time by notifying you in writing (including by email) and/or, if you are a registered user, by cancelling your account and your access to your account.
 
Nothing contained in these Terms will be deemed to constitute either party as the agent or representative of the other party, or both parties as joint venturers or partners for any purpose. You may not assign, delegate or transfer your rights or obligations under these Terms. We may assign our rights and duties under these Terms without such assignment being considered a change to the Terms and without notice to you, provided your rights under these Terms are not prejudiced. One or more patents may apply to this site and to the features and services accessible via the site, including—without limitation—United States Patent Numbers: 8,095,536; 7,979,457; 7,917,387; 7,774,331; 7,668,811; 7,668,809; 7,627,606; 7,587,678; 7,483,883; and 6,529,908.
 
If we fail to act with respect to your breach or anyone else's breach on any occasion, we are not waiving our right to act with respect to future or similar breaches. If a court finds any of these Terms to be unenforceable or invalid, that Term will be enforced to the fullest extent permitted by applicable law and the other Terms will remain valid and enforceable. These Terms, together with those agreements made a part of these Terms by reference, make up the entire agreement between us relating to your use of Our Website, including Trips, and replace any prior understandings or agreements (whether oral or written) regarding your use of Our Website.
 
To the extent permitted by law, the laws of the State of Connecticut (USA), without regard to its conflict of laws rules, will govern these Terms, as well as your and our observance of them. If you take any legal action relating to your use of Our Website, including Trips, or these Terms, or our services, you agree to file such action only in the state and federal courts located in New Haven, Connecticut (USA); if you are a consumer, the law may allow you to also bring proceedings in the courts for the place where you are domiciled. In any such action or any action we may initiate, the prevailing party will be entitled to recover all legal expenses incurred in connection with the action, including but not limited to costs, both taxable and non-taxable, and reasonable attorney fees. To the extent permitted by law, you agree that any disputes, claims and causes of action arising out of or connected with Our Website and/or these Terms, will be resolved individually, without resort to any form of class action.
 
<h1 className='my-5 text-[25px] font-bold text-[#1E88E5]'>13. Your Feedback</h1>
We encourage you to share your comments and questions with us, but we may not be able to respond to all of them. Please note that we assume no responsibility for reviewing unsolicited ideas for our business (like product or advertising ideas) and will not incur any liability as a result of any similarities between those ideas and materials that may appear in future FlightsUnlocked products or services. Also, please remember that you are responsible for whatever material you submit, including its reliability, originality, and copyright. Please do not reveal trade secrets or other confidential information in your messages. Any and all rights to materials submitted to us become the exclusive property of FlightsUnlocked. Furthermore, by submitting Feedback, you are granting us an irrevocable, perpetual, non-exclusive, transferable, fully-paid, worldwide license (with the right to freely sublicense) to use, copy, modify, publicly perform, publicly display, reformat, translate, syndicate, republish, excerpt (in whole or in part) and distribute Feedback we receive from you for any purpose, including business, commercial, marketing, advertising, or otherwise.
      
        </div>
      
        </div>
</IndexPage>
  )
}

export default Terms