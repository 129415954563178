import React, { useEffect } from 'react'
import FlightCardTxt from './FlightCardTxt'

import Collapse from '@mui/material/Collapse';

import { Button } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import WifiIcon from '@mui/icons-material/Wifi';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import FlightRow from './FlightRow';
import moment from 'moment';
import TripRow from './TripRow';

function FlightDetails(props) {


    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
      setChecked((prev) => !prev);
    };
    useEffect(() => {
      console.log(props.flight);
    }, [])
    
  return (
    <div>
        <div>
            <div className="flex justify-between">
            <p className='text-xs text-slate-500 mb-2'>{props.flight.fromCity} ({props.flight.fromIATA?.IATA_code}) -- {props.flight.toCity} ({props.flight.toIATA?.IATA_code})</p>
            {props.flight.depStop == 0 &&
            <div className="flex items-center gap-1">
                <h1 className='text-xs text-[grey]'>{props.flight.depFlightClass}</h1>
                <WifiIcon style={{fontSize : "15px", color: "grey"}}/>
                <PlayCircleOutlineIcon style={{fontSize : "15px", color: "grey"}}/>
                <PowerOutlinedIcon style={{fontSize : "15px", color: "grey"}}/>
            </div>
}
            </div>
            <hr />
        </div>
        <div className="w-full py-4">
        <FlightRow
        noAirline
        depAirlineLogo={props.flight.depAirline?.logo}
        depAirlineName={props.flight.depAirline?.name}
        returnAirlineLogo={props.flight.returnAirline?.logo}
        returnAirlineName={props.flight.returnAirline?.name}
        depDate={moment(props.flight.depDate).format('lll') }
        depArriveDate = {moment(props.flight.depArriveDate).format('lll') }
        fromIATA={props.flight.fromIATA?.IATA_code}
        toIATA={props.flight.toIATA?.IATA_code}
        depStop={props.flight.depStop}
        round={props.flight.tripType == "round"}
        returnDate={moment(props.flight.returnDate).format('lll') }
        returnArriveDate={moment(props.flight.returnArriveDate).format('lll') }
        returnStop={props.flight.returnStop}
        depDuration={props.flight.flightDuration}
        returnDuration={props.flight.returnFlightDuration}
        depAirNumber={props.flight.depAirNumber}
        returnAirNumber={props.flight.returnAirNumber}
         />


         
        </div>
    

        {(props.flight.depStop != 0 || props.flight.returnStop != 0) &&

       
          <div className='flex flex-col items-center w-full'>
          <Button className='btn' style={{textDecoration : "underline"}} onClick={()=>setChecked(!checked)}><ExpandCircleDownIcon style={{fontSize : "16px", marginRight : "1px"}} />Details <ExpandCircleDownIcon style={{fontSize : "16px", marginLeft : "1px"}} /></Button>
          {/* <ExpandCircleDownIcon onClick={()=>setChecked(!checked)} style={{fontSize : "40px",color : "grey", cursor : "pointer"}} /> */}
          <Collapse in={checked} className="w-full">
            {props.flight.depStop != 0 &&
          <div>
            <h1 className='text-[orange] text-[25px] font-bold my-2 text-center'>Departure Routes</h1>
            <div className='my-3 w-full mx-auto  rounded-xl h-[2px] bg-[orange]'></div>
            {props.flight.depTrips.map((trip)=>
             <TripRow
           
             airlineLogo={trip.airline?.logo}
             airNumber = {trip.airNumber}
          
             depDate={moment(trip.depDate).format('lll') }
             depArriveDate = {moment(trip.depArriveDate).format('lll') }
             fromIATA={trip.fromIATA?.IATA_code}
             toIATA={trip.toIATA?.IATA_code}
             fromCity={trip.fromCity}
             toCity={trip.toCity}
             tripClass ={trip.tripClass}
             layoverTime={trip.layoverTime}
             depDuration={trip.tripDuration}
              />
            )}
        </div>
         }
        
         {props.flight.returnStop != 0 &&

<div>
<h1 className='text-[orange] text-[25px] font-bold my-2 text-center'>Return Routes</h1>
<div className='my-3 w-full mx-auto  rounded-xl h-[2px] bg-[orange]'></div>

{props.flight.returnTrips.map((trip,idx)=>
 <TripRow
 key={idx}
 airlineLogo={trip.airline?.logo}
 airNumber = {trip.airNumber}

 depDate={moment(trip.depDate).format('lll') }
 depArriveDate = {moment(trip.depArriveDate).format('lll') }
 fromIATA={trip.fromIATA?.IATA_code}
 toIATA={trip.toIATA?.IATA_code}
 fromCity={trip.fromCity}
 toCity={trip.toCity}
 tripClass ={trip.tripClass}
 layoverTime={trip.layoverTime}
 depDuration={trip.tripDuration}
  />
)}
</div>
         }
        

          </Collapse>
          
        </div>
}
  
    </div>
  )
}

export default FlightDetails