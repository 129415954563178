import React from 'react'

function ContentCard(props) {
  return (
    <div className={`${props.w}`+ ` ${props.marginTop && "mt-5"} rounded-xl overflow-hidden shadow`}>
        {!props.noHeader && <h1 className='text-xl bg-[#1E88E5] p-3 text-center text-white font-bold'>{props.title}</h1>}
        <div className="bg-white p-4">
        {props.children}
        </div>
         
    </div>
  )
}

export default ContentCard